import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { id: "textDescription" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_sign_in_with_jira_cloud_button_component = _resolveComponent("sign-in-with-jira-cloud-button-component")!
  const _component_sign_in_with_jira_server_button_component = _resolveComponent("sign-in-with-jira-server-button-component")!
  const _component_sign_in_with_azure_cloud_button_component = _resolveComponent("sign-in-with-azure-cloud-button-component")!
  const _component_sign_in_with_git_hub_button_component = _resolveComponent("sign-in-with-git-hub-button-component")!
  const _component_sign_in_with_gitlab_button_component = _resolveComponent("sign-in-with-gitlab-button-component")!
  const _component_project_selection_component = _resolveComponent("project-selection-component")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("h5", null, [
      _createElementVNode("i", null, _toDisplayString(_ctx.t("session.prepare.step.selection.mode.description.withIssueTracker.subtitle")), 1)
    ]),
    _createElementVNode("ul", null, [
      _createElementVNode("li", null, [
        _createTextVNode(_toDisplayString(_ctx.t("session.prepare.step.selection.mode.description.withIssueTracker.descriptionLine1")) + " ", 1),
        (_ctx.isJiraCloudEnabled)
          ? (_openBlock(), _createBlock(_component_sign_in_with_jira_cloud_button_component, {
              key: 0,
              class: "my-1"
            }))
          : _createCommentVNode("", true),
        (_ctx.isJiraServerEnabled)
          ? (_openBlock(), _createBlock(_component_sign_in_with_jira_server_button_component, {
              key: 1,
              class: "my-1",
              onJira: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('jira')))
            }))
          : _createCommentVNode("", true),
        (_ctx.isAzureDevOpsEnabled)
          ? (_openBlock(), _createBlock(_component_sign_in_with_azure_cloud_button_component, {
              key: 2,
              class: "my-1"
            }))
          : _createCommentVNode("", true),
        (_ctx.isGithubEnabled)
          ? (_openBlock(), _createBlock(_component_sign_in_with_git_hub_button_component, {
              key: 3,
              class: "my-1"
            }))
          : _createCommentVNode("", true),
        (_ctx.isGitlabEnabled)
          ? (_openBlock(), _createBlock(_component_sign_in_with_gitlab_button_component, {
              key: 4,
              class: "my-1"
            }))
          : _createCommentVNode("", true)
      ]),
      _createElementVNode("li", null, [
        _createTextVNode(_toDisplayString(_ctx.t("session.prepare.step.selection.mode.description.withIssueTracker.descriptionLine2")) + " ", 1),
        (_ctx.isLoggedInWithJira)
          ? (_openBlock(), _createBlock(_component_project_selection_component, {
              key: 0,
              class: "my-1"
            }))
          : _createCommentVNode("", true)
      ]),
      _createElementVNode("li", null, _toDisplayString(_ctx.t("session.prepare.step.selection.mode.description.withIssueTracker.descriptionLine3")), 1),
      _createElementVNode("li", null, _toDisplayString(_ctx.t("session.prepare.step.selection.mode.description.withIssueTracker.descriptionLine4")), 1)
    ])
  ]))
}