import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveDirective as _resolveDirective, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, withDirectives as _withDirectives, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "img-holderClose"
}
const _hoisted_2 = { id: "picture-holderClose" }
const _hoisted_3 = { class: "modal-body my-4 description" }
const _hoisted_4 = {
  key: 0,
  class: "description"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_b_img = _resolveComponent("b-img")!
  const _component_b_icon_x = _resolveComponent("b-icon-x")!
  const _component_b_button = _resolveComponent("b-button")!
  const _component_b_modal = _resolveComponent("b-modal")!
  const _directive_b_modal = _resolveDirective("b-modal")!

  return (_ctx.isPlanningStart)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          _createVNode(_component_b_img, {
            id: "pandaPictureClose",
            src: require('@/assets/LeaveButton.png')
          }, null, 8, ["src"])
        ]),
        _withDirectives((_openBlock(), _createBlock(_component_b_button, {
          variant: "danger",
          class: "button"
        }, {
          default: _withCtx(() => [
            _createVNode(_component_b_icon_x),
            _createTextVNode(" " + _toDisplayString(_ctx.t("page.session.during.estimation.buttons.finish")), 1)
          ], undefined, true),
          _: 1
        })), [
          [
            _directive_b_modal,
            void 0,
            void 0,
            { "close-session-modal": true }
          ]
        ]),
        _createVNode(_component_b_modal, {
          id: "close-session-modal",
          class: "modal-header",
          title: _ctx.t('page.session.close.popup'),
          "cancel-title": _ctx.t('page.session.close.button.cancel'),
          "ok-title": _ctx.t('page.session.close.button.ok'),
          onOk: _ctx.closeSession
        }, {
          default: _withCtx(() => [
            _createElementVNode("p", _hoisted_3, _toDisplayString(_ctx.t("page.session.close.description1")), 1),
            (_ctx.userStoryMode !== 'NO_US')
              ? (_openBlock(), _createElementBlock("p", _hoisted_4, _toDisplayString(_ctx.t("page.session.close.description2")), 1))
              : _createCommentVNode("", true)
          ], undefined, true),
          _: 1
        }, 8, ["title", "cancel-title", "ok-title", "onOk"])
      ]))
    : _createCommentVNode("", true)
}