import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "py-5" }
const _hoisted_2 = { href: "https://docs.diveni.io" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_link = _resolveComponent("router-link")!
  const _component_b_container = _resolveComponent("b-container")!

  return (_openBlock(), _createElementBlock("footer", _hoisted_1, [
    _createVNode(_component_b_container, null, {
      default: _withCtx(() => [
        _createElementVNode("div", null, [
          _createTextVNode(" © 2022-" + _toDisplayString(_ctx.currentYear) + " Diveni | ", 1),
          _createElementVNode("a", _hoisted_2, _toDisplayString(_ctx.t("general.about.docs")), 1),
          _cache[0] || (_cache[0] = _createTextVNode(" | ")),
          _createVNode(_component_router_link, { to: "/about" }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.t("general.about.label")), 1)
            ], undefined, true),
            _: 1
          }),
          _cache[1] || (_cache[1] = _createTextVNode(" | ")),
          _createVNode(_component_router_link, { to: "/whats-new" }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.$t("page.landing.news.buttons.info.label")), 1)
            ], undefined, true),
            _: 1
          })
        ]),
        _cache[2] || (_cache[2] = _createElementVNode("div", null, "Made with ❤️ by Diveni Development Team", -1))
      ], undefined, true),
      _: 1
    })
  ]))
}