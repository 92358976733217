import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, normalizeClass as _normalizeClass, createBlock as _createBlock, normalizeStyle as _normalizeStyle, createTextVNode as _createTextVNode } from "vue"

const _hoisted_1 = { id: "splitUserStoriesModal" }
const _hoisted_2 = { class: "text-center" }
const _hoisted_3 = { class: "text-center font-italic" }
const _hoisted_4 = { class: "text-center font-italic" }
const _hoisted_5 = { class: "text-center font-italic" }
const _hoisted_6 = {
  id: "aiOptions",
  class: "text-center mt-1"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_b_form_textarea = _resolveComponent("b-form-textarea")!
  const _component_UiToastEditorWrapper = _resolveComponent("UiToastEditorWrapper")!
  const _component_b_col = _resolveComponent("b-col")!
  const _component_b_form_input = _resolveComponent("b-form-input")!
  const _component_b_icon_trash = _resolveComponent("b-icon-trash")!
  const _component_b_button = _resolveComponent("b-button")!
  const _component_b_list_group_item = _resolveComponent("b-list-group-item")!
  const _component_b_card_group = _resolveComponent("b-card-group")!
  const _component_b_row = _resolveComponent("b-row")!
  const _component_b_container = _resolveComponent("b-container")!
  const _component_b_icon_check2 = _resolveComponent("b-icon-check2")!
  const _component_b_icon_arrow_repeat = _resolveComponent("b-icon-arrow-repeat")!
  const _component_b_icon_backspace = _resolveComponent("b-icon-backspace")!
  const _component_b_modal = _resolveComponent("b-modal")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_b_modal, {
      modelValue: _ctx.showModal,
      "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.showModal) = $event)),
      centered: "",
      "hide-header-close": "",
      size: "xl",
      onHide: _ctx.hideModal
    }, {
      "modal-header": _withCtx(() => [
        _createElementVNode("div", _hoisted_2, _toDisplayString(_ctx.t("general.aiFeature.splitUserStoriesModal.title")), 1)
      ]),
      "modal-footer": _withCtx(() => [
        _createElementVNode("div", _hoisted_6, [
          _createVNode(_component_b_button, {
            id: "acceptAISplitButton",
            class: "m-1",
            onClick: _ctx.acceptDescription
          }, {
            default: _withCtx(() => [
              _createVNode(_component_b_icon_check2),
              _cache[4] || (_cache[4] = _createTextVNode(" Keep "))
            ], undefined, true),
            _: 1
          }, 8, ["onClick"]),
          _createVNode(_component_b_button, {
            class: "aiOptionButtons m-1",
            onClick: _cache[2] || (_cache[2] = ($event: any) => {
              _ctx.retryDescription();
              $event.target.blur();
            })
          }, {
            default: _withCtx(() => [
              _createVNode(_component_b_icon_arrow_repeat),
              _cache[5] || (_cache[5] = _createTextVNode(" Try Again "))
            ], undefined, true),
            _: 1
          }),
          _createVNode(_component_b_button, {
            class: "aiOptionButtons m-1",
            onClick: _ctx.deleteDescription
          }, {
            default: _withCtx(() => [
              _createVNode(_component_b_icon_backspace),
              _cache[6] || (_cache[6] = _createTextVNode(" Delete "))
            ], undefined, true),
            _: 1
          }, 8, ["onClick"])
        ])
      ]),
      default: _withCtx(() => [
        _createVNode(_component_b_container, { class: "max-height-modal overflow-auto" }, {
          default: _withCtx(() => [
            _createVNode(_component_b_row, null, {
              default: _withCtx(() => [
                _createVNode(_component_b_col, null, {
                  default: _withCtx(() => [
                    _createElementVNode("div", _hoisted_3, _toDisplayString(_ctx.t("general.aiFeature.splitUserStoriesModal.originalStory")), 1),
                    _createVNode(_component_b_form_textarea, {
                      modelValue: _ctx.originalTitleData,
                      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.originalTitleData) = $event)),
                      class: "overflow-auto font-weight-bold titleInputField",
                      disabled: true
                    }, null, 8, ["modelValue"]),
                    _createVNode(_component_UiToastEditorWrapper, {
                      class: "editor",
                      "initial-value": _ctx.originalUserStory[0].description,
                      "none-clickable": true
                    }, null, 8, ["initial-value"])
                  ], undefined, true),
                  _: 1
                }),
                _createVNode(_component_b_col, null, {
                  default: _withCtx(() => [
                    _createElementVNode("div", _hoisted_4, _toDisplayString(_ctx.t("general.aiFeature.splitUserStoriesModal.newStories")), 1),
                    _createVNode(_component_b_card_group, {
                      id: "userStoryBlock",
                      class: "overflow-auto center"
                    }, {
                      default: _withCtx(() => [
                        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.newUserStoriesEditable, (story, index) => {
                          return (_openBlock(), _createBlock(_component_b_list_group_item, {
                            id: "userStoryRow",
                            key: index,
                            class: _normalizeClass(["w-100 p-1 d-flex justify-content-left", index === _ctx.idx ? 'selected-Story' : '']),
                            onClick: ($event: any) => (_ctx.switchToUserStory(index))
                          }, {
                            default: _withCtx(() => [
                              _createVNode(_component_b_form_input, {
                                id: "userStoryTitles",
                                modelValue: story.title,
                                "onUpdate:modelValue": ($event: any) => ((story.title) = $event),
                                class: "mx-1 w-100 shadow-none",
                                readonly: "",
                                size: "sm",
                                placeholder: _ctx.t('page.session.before.userStories.placeholder.userStoryTitle')
                              }, null, 8, ["modelValue", "onUpdate:modelValue", "placeholder"]),
                              _createVNode(_component_b_button, {
                                variant: "outline-danger",
                                class: "border-0",
                                size: "sm",
                                onClick: ($event: any) => (_ctx.deleteStory(index))
                              }, {
                                default: _withCtx(() => [
                                  _createVNode(_component_b_icon_trash)
                                ], undefined, true),
                                _: 2
                              }, 1032, ["onClick"])
                            ], undefined, true),
                            _: 2
                          }, 1032, ["class", "onClick"]))
                        }), 128)),
                        _createElementVNode("div", {
                          class: "selectedStoryLine",
                          style: _normalizeStyle({ top: 50 + 50 * _ctx.idx + 'px' })
                        }, null, 4)
                      ], undefined, true),
                      _: 1
                    })
                  ], undefined, true),
                  _: 1
                }),
                _createVNode(_component_b_col, { class: "newUserStoryCol" }, {
                  default: _withCtx(() => [
                    _createElementVNode("div", _hoisted_5, _toDisplayString(_ctx.t("general.aiFeature.splitUserStoriesModal.selectedStory")), 1),
                    _createVNode(_component_b_form_textarea, {
                      modelValue: _ctx.newUserStoriesEditable[_ctx.idx].title,
                      "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.newUserStoriesEditable[_ctx.idx].title) = $event)),
                      class: "overflow-auto font-weight-bold titleInputField",
                      rows: "1",
                      size: "lg",
                      disabled: ""
                    }, null, 8, ["modelValue"]),
                    (_openBlock(), _createBlock(_component_UiToastEditorWrapper, {
                      key: _ctx.idx,
                      class: "editor",
                      "initial-value": _ctx.newUserStoriesEditable[_ctx.idx].description,
                      "none-clickable": true
                    }, null, 8, ["initial-value"]))
                  ], undefined, true),
                  _: 1
                })
              ], undefined, true),
              _: 1
            })
          ], undefined, true),
          _: 1
        })
      ], undefined, true),
      _: 1
    }, 8, ["modelValue", "onHide"])
  ]))
}