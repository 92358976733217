import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveDirective as _resolveDirective, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, withDirectives as _withDirectives, normalizeClass as _normalizeClass, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { id: "picture-holderLeave" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_b_img = _resolveComponent("b-img")!
  const _component_b_icon_x = _resolveComponent("b-icon-x")!
  const _component_b_button = _resolveComponent("b-button")!
  const _directive_b_modal = _resolveDirective("b-modal")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["img-holderLeave", _ctx.isMobile ? '' : 'mt-5'])
  }, [
    _createElementVNode("div", _hoisted_1, [
      _createVNode(_component_b_img, {
        id: "pandaPictureLeave",
        src: require('@/assets/LeaveButton.png')
      }, null, 8, ["src"])
    ]),
    _withDirectives((_openBlock(), _createBlock(_component_b_button, {
      variant: "danger",
      class: "mt-4 button",
      onClick: _ctx.leaveSession
    }, {
      default: _withCtx(() => [
        _createVNode(_component_b_icon_x),
        _createTextVNode(" " + _toDisplayString(_ctx.t("page.vote.button.leave.label")), 1)
      ], undefined, true),
      _: 1
    }, 8, ["onClick"])), [
      [
        _directive_b_modal,
        void 0,
        void 0,
        { "close-session-modal": true }
      ]
    ])
  ], 2))
}