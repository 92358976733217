import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "mx-2 centerItems" }
const _hoisted_2 = {
  key: 0,
  class: "text-center"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_b_button = _resolveComponent("b-button")!
  const _component_b_list_group_item = _resolveComponent("b-list-group-item")!
  const _component_b_list_group = _resolveComponent("b-list-group")!
  const _component_b_col = _resolveComponent("b-col")!
  const _component_b_row = _resolveComponent("b-row")!
  const _component_b_container = _resolveComponent("b-container")!

  return (_openBlock(), _createBlock(_component_b_container, null, {
    default: _withCtx(() => [
      _createElementVNode("h1", _hoisted_1, _toDisplayString(_ctx.t("page.results.title")), 1),
      (_ctx.userStories.length === 0)
        ? (_openBlock(), _createElementBlock("div", _hoisted_2, _toDisplayString(_ctx.t("page.results.noUserStories")), 1))
        : (_openBlock(), _createBlock(_component_b_list_group, {
            key: 1,
            id: "results"
          }, {
            default: _withCtx(() => [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.userStories, (story, index) => {
                return (_openBlock(), _createBlock(_component_b_list_group_item, {
                  key: index,
                  class: "text-center stories"
                }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(story.title ? story.title : "No title ...") + " ", 1),
                    _createVNode(_component_b_button, {
                      class: "mx-2",
                      pill: ""
                    }, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(story.estimation ? story.estimation : "?"), 1)
                      ], undefined, true),
                      _: 2
                    }, 1024)
                  ], undefined, true),
                  _: 2
                }, 1024))
              }), 128))
            ], undefined, true),
            _: 1
          })),
      _createVNode(_component_b_row, { class: "text-center centerItems" }, {
        default: _withCtx(() => [
          _createVNode(_component_b_col, null, {
            default: _withCtx(() => [
              _createVNode(_component_b_button, {
                variant: "primary",
                disabled: _ctx.userStories.length === 0,
                onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.downloadUserStoriesAsCSV()))
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.t("page.results.button.download")), 1)
                ], undefined, true),
                _: 1
              }, 8, ["disabled"]),
              _createVNode(_component_b_button, {
                variant: "primary",
                class: "mx-2",
                onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.goHome()))
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.t("page.results.button.home")), 1)
                ], undefined, true),
                _: 1
              })
            ], undefined, true),
            _: 1
          })
        ], undefined, true),
        _: 1
      })
    ], undefined, true),
    _: 1
  }))
}