import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, withCtx as _withCtx, createTextVNode as _createTextVNode, normalizeStyle as _normalizeStyle } from "vue"

const _hoisted_1 = { class: "title" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_b_img_lazy = _resolveComponent("b-img-lazy")!
  const _component_b_card_title = _resolveComponent("b-card-title")!
  const _component_b_card_text = _resolveComponent("b-card-text")!
  const _component_b_card = _resolveComponent("b-card")!

  return (_openBlock(), _createElementBlock("div", {
    class: "row",
    onMouseenter: _cache[0] || (_cache[0] = ($event: any) => (_ctx.stopItems = true)),
    onMouseleave: _cache[1] || (_cache[1] = ($event: any) => (_ctx.stopItems = false))
  }, [
    _createElementVNode("div", {
      class: "wrapper",
      style: _normalizeStyle({ transform: `translateX(-${(_ctx.updateValue * 4) / _ctx.items.length}%)` })
    }, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.allItems, (item, index) => {
        return (_openBlock(), _createElementBlock("div", {
          key: index,
          ref_for: true,
          ref: "carousel",
          class: "set"
        }, [
          _createVNode(_component_b_card, {
            class: _normalizeClass(["connectorCards", _ctx.getClass(item)]),
            onClick: ($event: any) => (_ctx.redirectToDoc(item))
          }, {
            default: _withCtx(() => [
              _createVNode(_component_b_card_title, null, {
                default: _withCtx(() => [
                  _createElementVNode("div", null, [
                    _createVNode(_component_b_img_lazy, {
                      src: require(`@/assets/${_ctx.getClass(item).toLowerCase()}.png`),
                      class: _normalizeClass(_ctx.getClass(item) + 'Pic'),
                      alt: "Connector Picture"
                    }, null, 8, ["src", "class"])
                  ]),
                  _createElementVNode("div", _hoisted_1, _toDisplayString(item.title), 1)
                ], undefined, true),
                _: 2
              }, 1024),
              _createVNode(_component_b_card_text, null, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(item.description), 1)
                ], undefined, true),
                _: 2
              }, 1024)
            ], undefined, true),
            _: 2
          }, 1032, ["class", "onClick"])
        ]))
      }), 128))
    ], 4)
  ], 32))
}