import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "card-title" }
const _hoisted_2 = { class: "card-description" }
const _hoisted_3 = { key: 0 }
const _hoisted_4 = { id: "createSetHint" }
const _hoisted_5 = {
  key: 0,
  class: "text-center mt-3 pill-group"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_b_icon_info_circle = _resolveComponent("b-icon-info-circle")!
  const _component_b_popover = _resolveComponent("b-popover")!
  const _component_b_col = _resolveComponent("b-col")!
  const _component_b_row = _resolveComponent("b-row")!
  const _component_b_button = _resolveComponent("b-button")!
  const _component_b_form_input = _resolveComponent("b-form-input")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(_component_b_row, {
      class: _normalizeClass(_ctx.isMobile ? 'd-flex justify-content-center' : 'd-flex justify-content-around')
    }, {
      default: _withCtx(() => [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.userStoryMode === _ctx.jiraTag ? _ctx.allCardSetsWithJiraMode : _ctx.allCardSets, (item) => {
          return (_openBlock(), _createBlock(_component_b_col, {
            key: item.position,
            cols: _ctx.isMobile ? '3' : '',
            class: _normalizeClass(["card m-2", _ctx.getClasses(item)]),
            onClick: ($event: any) => (_ctx.onCardSetSelected(item))
          }, {
            default: _withCtx(() => [
              _createElementVNode("div", _hoisted_1, _toDisplayString(_ctx.getTitle(item.position, _ctx.userStoryMode !== _ctx.jiraTag)), 1),
              _createElementVNode("div", _hoisted_2, [
                _createTextVNode(_toDisplayString(_ctx.getDescription(item.position, _ctx.userStoryMode !== _ctx.jiraTag)) + " ", 1),
                (item.values.length === 0)
                  ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
                      _createElementVNode("span", _hoisted_4, [
                        _createVNode(_component_b_icon_info_circle, { class: "mt-3 me-1" }),
                        _createTextVNode(_toDisplayString(_ctx.t("session.prepare.step.selection.cardSet.sets.ownSet.hint.label")), 1)
                      ]),
                      _createVNode(_component_b_popover, {
                        id: "popUp",
                        target: "createSetHint",
                        triggers: "hover",
                        placement: "top"
                      }, {
                        title: _withCtx(() => [
                          _createTextVNode(_toDisplayString(_ctx.t("session.prepare.step.selection.cardSet.sets.ownSet.hint.label")), 1)
                        ]),
                        default: _withCtx(() => [
                          _createElementVNode("p", null, _toDisplayString(_ctx.t("session.prepare.step.selection.cardSet.sets.ownSet.hint.description")), 1),
                          _createElementVNode("p", null, _toDisplayString(_ctx.t("session.prepare.step.selection.cardSet.sets.ownSet.hint.example")), 1)
                        ], undefined, true),
                        _: 1
                      })
                    ]))
                  : _createCommentVNode("", true)
              ])
            ], undefined, true),
            _: 2
          }, 1032, ["cols", "class", "onClick"]))
        }), 128))
      ], undefined, true),
      _: 1
    }, 8, ["class"]),
    (_ctx.changedCardSet.name !== '')
      ? (_openBlock(), _createBlock(_component_b_row, { key: 0 }, {
          default: _withCtx(() => [
            (_ctx.changedCardSet.values.length !== 0)
              ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.changedCardSet.values, (item) => {
                    return (_openBlock(), _createBlock(_component_b_button, {
                      key: item,
                      class: _normalizeClass([_ctx.isActiveCardSetNumber(item), "pill m-2"]),
                      pill: "",
                      onClick: ($event: any) => {
            _ctx.onCardSetNumberSelected(item);
            $event.target.blur();
          }
                    }, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(item), 1)
                      ], undefined, true),
                      _: 2
                    }, 1032, ["class", "onClick"]))
                  }), 128))
                ]))
              : (_openBlock(), _createBlock(_component_b_row, {
                  key: 1,
                  class: "mt-3 pill-group"
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_b_col, { sm: "6" }, {
                      default: _withCtx(() => [
                        _createVNode(_component_b_form_input, {
                          modelValue: _ctx.createSetInput,
                          "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.createSetInput) = $event)),
                          placeholder: _ctx.t('session.prepare.step.selection.cardSet.sets.ownSet.hint.example')
                        }, null, 8, ["modelValue", "placeholder"])
                      ], undefined, true),
                      _: 1
                    }),
                    _createVNode(_component_b_col, { sm: "6" }, {
                      default: _withCtx(() => [
                        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.changedCardSet.activeValues, (item) => {
                          return (_openBlock(), _createBlock(_component_b_button, {
                            key: item,
                            class: _normalizeClass([_ctx.isActiveCardSetNumber(item), "pill"]),
                            pill: ""
                          }, {
                            default: _withCtx(() => [
                              _createTextVNode(_toDisplayString(item), 1)
                            ], undefined, true),
                            _: 2
                          }, 1032, ["class"]))
                        }), 128))
                      ], undefined, true),
                      _: 1
                    })
                  ], undefined, true),
                  _: 1
                }))
          ], undefined, true),
          _: 1
        }))
      : _createCommentVNode("", true)
  ]))
}