import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "my-2"
}
const _hoisted_2 = { class: "inline-div input-div" }
const _hoisted_3 = { class: "inline-div" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_b_button = _resolveComponent("b-button")!
  const _component_b_input = _resolveComponent("b-input")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(_component_b_button, {
      variant: "primary",
      disabled: _ctx.disabled,
      onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.clicked = !_ctx.clicked))
    }, {
      default: _withCtx(() => [
        _createTextVNode(_toDisplayString(_ctx.t(
          "session.prepare.step.selection.mode.description.withIssueTracker.buttons.signInWithGitlab.label"
        )), 1)
      ], undefined, true),
      _: 1
    }, 8, ["disabled"]),
    (_ctx.clicked)
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
          _createElementVNode("div", _hoisted_2, [
            _createVNode(_component_b_input, {
              modelValue: _ctx.patToken,
              "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.patToken) = $event)),
              class: "patInputField",
              placeholder: "Type in your PAT",
              type: "password"
            }, null, 8, ["modelValue"])
          ]),
          _createElementVNode("div", _hoisted_3, [
            _createVNode(_component_b_button, {
              variant: "primary",
              disabled: _ctx.patToken === '',
              onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.getAccessToken()))
            }, {
              default: _withCtx(() => _cache[3] || (_cache[3] = [
                _createTextVNode("Sign in ")
              ]), undefined, true),
              _: 1
            }, 8, ["disabled"])
          ])
        ]))
      : _createCommentVNode("", true)
  ]))
}