import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "flex" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", {
      class: "mode-toggle",
      onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.modeToggle && _ctx.modeToggle(...args)))
    }, _cache[1] || (_cache[1] = [
      _createElementVNode("div", { class: "toggle" }, [
        _createElementVNode("div", {
          id: "dark-mode",
          type: "checkbox"
        })
      ], -1)
    ]))
  ]))
}