import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_top_navigation_bar = _resolveComponent("top-navigation-bar")!
  const _component_feedback_button = _resolveComponent("feedback-button")!
  const _component_router_view = _resolveComponent("router-view")!
  const _component_footer_bar = _resolveComponent("footer-bar")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(_component_top_navigation_bar),
    _createElementVNode("main", null, [
      _createVNode(_component_feedback_button),
      _createVNode(_component_router_view)
    ]),
    _createVNode(_component_footer_bar)
  ]))
}