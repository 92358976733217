import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = {
  key: 0,
  "align-h": "between"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_ctx.userStorySum > 0)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createElementVNode("h4", null, [
          _createTextVNode(_toDisplayString(_ctx.t("page.session.before.userStories.sum")) + " ", 1),
          _createElementVNode("span", null, _toDisplayString(_ctx.userStorySum), 1)
        ])
      ]))
    : _createCommentVNode("", true)
}