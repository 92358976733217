import { resolveDynamicComponent as _resolveDynamicComponent, openBlock as _openBlock, createBlock as _createBlock, resolveComponent as _resolveComponent, createVNode as _createVNode, resolveDirective as _resolveDirective, withCtx as _withCtx, withDirectives as _withDirectives, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, normalizeStyle as _normalizeStyle, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = ["id"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_b_icon = _resolveComponent("b-icon")!
  const _component_b_button = _resolveComponent("b-button")!
  const _component_b_popover = _resolveComponent("b-popover")!
  const _component_b_modal = _resolveComponent("b-modal")!
  const _directive_b_modal = _resolveDirective("b-modal")!

  return (_openBlock(), _createElementBlock("div", {
    id: `user${_ctx.member.memberID}`,
    class: "component",
    style: _normalizeStyle({ width: _ctx.width })
  }, [
    (_openBlock(), _createBlock(_resolveDynamicComponent(_ctx.child), {
      member: _ctx.member,
      props: _ctx.props
    }, null, 8, ["member", "props"])),
    _createVNode(_component_b_popover, {
      target: `user${_ctx.member.memberID}`,
      triggers: "hover",
      placement: "top",
      boundary: "viewport"
    }, {
      default: _withCtx(() => [
        _withDirectives((_openBlock(), _createBlock(_component_b_button, {
          class: "rounded-circle px-2",
          variant: "danger"
        }, {
          default: _withCtx(() => [
            _createVNode(_component_b_icon, {
              icon: "x",
              scale: "2"
            })
          ], undefined, true),
          _: 1
        })), [
          [_directive_b_modal, `modal-${_ctx.member.memberID}`]
        ])
      ], undefined, true),
      _: 1
    }, 8, ["target"]),
    _createVNode(_component_b_modal, {
      id: `modal-${_ctx.member.memberID}`,
      class: "modal-header",
      title: _ctx.t('page.session.during.modal.title'),
      "cancel-title": _ctx.t('page.session.during.modal.buttons.cancel'),
      "ok-title": _ctx.t('page.session.during.modal.buttons.ok'),
      onOk: _ctx.removeMember
    }, {
      default: _withCtx(() => [
        _createTextVNode(_toDisplayString(_ctx.t("page.session.during.modal.bodyPart1")) + " ", 1),
        _createElementVNode("b", null, _toDisplayString(_ctx.member.name), 1),
        _createTextVNode(" " + _toDisplayString(_ctx.t("page.session.during.modal.bodyPart2")), 1)
      ], undefined, true),
      _: 1
    }, 8, ["id", "title", "cancel-title", "ok-title", "onOk"])
  ], 12, _hoisted_1))
}