import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, resolveDirective as _resolveDirective, openBlock as _openBlock, createBlock as _createBlock, withDirectives as _withDirectives, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "copy-session" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_b_link = _resolveComponent("b-link")!
  const _component_b_button = _resolveComponent("b-button")!
  const _component_b_popover = _resolveComponent("b-popover")!
  const _component_qrcode_vue = _resolveComponent("qrcode-vue")!
  const _component_b_modal = _resolveComponent("b-modal")!
  const _directive_b_modal = _resolveDirective("b-modal")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createTextVNode(_toDisplayString(_ctx.textBeforeSessionID) + " ", 1),
    _createElementVNode("strong", null, [
      _createVNode(_component_b_link, {
        id: "popover-link",
        href: "#",
        onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.copyLinkToClipboard()))
      }, {
        default: _withCtx(() => [
          _createTextVNode(_toDisplayString(_ctx.sessionId), 1)
        ], undefined, true),
        _: 1
      })
    ]),
    _createTextVNode(" " + _toDisplayString(_ctx.textAfterSessionID) + " ", 1),
    _createVNode(_component_b_popover, {
      id: "popover",
      target: "popover-link",
      triggers: "hover",
      placement: "top"
    }, {
      default: _withCtx(() => [
        _createVNode(_component_b_button, {
          variant: "primary",
          class: "mx-1 my-1",
          onClick: _cache[1] || (_cache[1] = ($event: any) => {
          _ctx.copyIdToClipboard();
          $event.target.blur();
        })
        }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.t("page.session.before.copy.id")), 1)
          ], undefined, true),
          _: 1
        }),
        _createVNode(_component_b_button, {
          class: "mx-1 my-1",
          variant: "secondary",
          onClick: _cache[2] || (_cache[2] = ($event: any) => {
          _ctx.copyLinkToClipboard();
          $event.target.blur();
        })
        }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.t("page.session.before.copy.link")), 1)
          ], undefined, true),
          _: 1
        }),
        _withDirectives((_openBlock(), _createBlock(_component_b_button, {
          class: "mx-1 my-1",
          variant: "info",
          onClick: _cache[3] || (_cache[3] = ($event: any) => ($event.target.blur()))
        }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.t("page.session.before.copy.qr")), 1)
          ], undefined, true),
          _: 1
        })), [
          [
            _directive_b_modal,
            void 0,
            void 0,
            { "qr-modal": true }
          ]
        ])
      ], undefined, true),
      _: 1
    }),
    _createVNode(_component_b_modal, {
      id: "qr-modal",
      "ok-only": ""
    }, {
      "modal-header": _withCtx(() => _cache[4] || (_cache[4] = [
        _createElementVNode("h3", null, "QR code", -1)
      ])),
      default: _withCtx(() => [
        _createVNode(_component_qrcode_vue, {
          value: _ctx.sessionLink,
          size: "400",
          class: "qr-code"
        }, null, 8, ["value"])
      ], undefined, true),
      _: 1
    })
  ]))
}