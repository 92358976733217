import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle } from "vue"

const _hoisted_1 = ["id"]
const _hoisted_2 = {
  key: 0,
  class: "rounded-avatar__label"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_b_img = _resolveComponent("b-img")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["rounded-avatar rounded-circle", _ctx.mobile ? 'smallAvatar' : 'bigAvatar']),
    style: _normalizeStyle(`background-color: ${_ctx.member.hexColor}`)
  }, [
    _createElementVNode("div", {
      id: 'avatar' + _ctx.member.name,
      class: "text-center"
    }, [
      _createVNode(_component_b_img, {
        src: require(`@/assets/${_ctx.avatar}.png`),
        class: "rounded-avatar__image"
      }, null, 8, ["src"]),
      (_ctx.showName)
        ? (_openBlock(), _createElementBlock("div", _hoisted_2, _toDisplayString(_ctx.member.name), 1))
        : _createCommentVNode("", true)
    ], 8, _hoisted_1)
  ], 6))
}