import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle } from "vue"

const _hoisted_1 = { class: "fs-3-rem" }
const _hoisted_2 = { key: 0 }
const _hoisted_3 = { key: 1 }
const _hoisted_4 = { key: 2 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_rounded_avatar = _resolveComponent("rounded-avatar")!

  return (_openBlock(), _createElementBlock("div", {
    style: _normalizeStyle(`background-color: ${_ctx.member.hexColor};`),
    class: _normalizeClass(["session-member-card text-center card m-2", { greyOut: !_ctx.props.highlight && _ctx.props.estimateFinished }])
  }, [
    _createElementVNode("h1", _hoisted_1, [
      (!_ctx.props.estimateFinished)
        ? (_openBlock(), _createElementBlock("strong", _hoisted_2, "?"))
        : _createCommentVNode("", true),
      (_ctx.member.currentEstimation === null && _ctx.props.estimateFinished)
        ? (_openBlock(), _createElementBlock("strong", _hoisted_3, "-"))
        : _createCommentVNode("", true),
      (_ctx.member.currentEstimation !== null && _ctx.props.estimateFinished)
        ? (_openBlock(), _createElementBlock("strong", _hoisted_4, _toDisplayString(_ctx.member.currentEstimation), 1))
        : _createCommentVNode("", true)
    ]),
    _createVNode(_component_rounded_avatar, {
      member: _ctx.member,
      admin: false
    }, null, 8, ["member"])
  ], 6))
}