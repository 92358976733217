import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeStyle as _normalizeStyle, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { ref: "card" }
const _hoisted_2 = { key: 1 }
const _hoisted_3 = { class: "text" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.isMobile)
      ? (_openBlock(), _createElementBlock("div", { key: _ctx.dragged }, [
          _createElementVNode("div", {
            ref: "swippable",
            class: "swipe-card-mobile",
            style: _normalizeStyle([_ctx.swipeableCardBackgroundColor, { marginBottom: _ctx.position }])
          }, [
            _createElementVNode("div", {
              class: "text-mobile",
              onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.onCardClicked()))
            }, _toDisplayString(_ctx.dragged ? "💪" : _ctx.voteOption), 1)
          ], 4)
        ]))
      : (_openBlock(), _createElementBlock("div", _hoisted_2, [
          _createElementVNode("div", {
            class: "swipe-card",
            style: _normalizeStyle(_ctx.swipeableCardBackgroundColor),
            onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.onCardClicked()))
          }, [
            _createElementVNode("div", _hoisted_3, _toDisplayString(_ctx.dragged ? "💪" : _ctx.voteOption), 1)
          ], 4)
        ]))
  ], 512))
}