import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, normalizeStyle as _normalizeStyle, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "d-flex justify-content-end estimate-timer" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_b_card_text = _resolveComponent("b-card-text")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_b_card_text, {
      style: _normalizeStyle(`color: ${_ctx.textColor}`),
      class: "timer"
    }, {
      default: _withCtx(() => [
        _createTextVNode(_toDisplayString(_ctx.formatTimer()), 1)
      ], undefined, true),
      _: 1
    }, 8, ["style"])
  ]))
}