import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", null, [
    _createElementVNode("h5", null, [
      _createElementVNode("i", null, _toDisplayString(_ctx.t("session.prepare.step.selection.mode.description.withUS.subtitle")), 1)
    ]),
    _createElementVNode("ul", null, [
      _createElementVNode("li", null, _toDisplayString(_ctx.t("session.prepare.step.selection.mode.description.withUS.descriptionLine1")), 1),
      _createElementVNode("li", null, _toDisplayString(_ctx.t("session.prepare.step.selection.mode.description.withUS.descriptionLine2")), 1),
      _createElementVNode("li", null, _toDisplayString(_ctx.t("session.prepare.step.selection.mode.description.withUS.descriptionLine3")), 1),
      _createElementVNode("li", null, _toDisplayString(_ctx.t("session.prepare.step.selection.mode.description.withUS.descriptionLine4")), 1),
      _createElementVNode("li", null, _toDisplayString(_ctx.t("session.prepare.step.selection.mode.description.withUS.descriptionLine5")), 1)
    ])
  ]))
}