import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, normalizeClass as _normalizeClass, createVNode as _createVNode, createElementBlock as _createElementBlock, withCtx as _withCtx } from "vue"

const _hoisted_1 = { class: "wizardStep" }
const _hoisted_2 = { class: "mb-3" }
const _hoisted_3 = { class: "mode-icons mt-2 d-flex justify-content-around" }
const _hoisted_4 = { class: "mode-icon-text" }
const _hoisted_5 = { class: "mode-icon-text" }
const _hoisted_6 = { class: "mode-icon-text" }
const _hoisted_7 = { class: "mt-5" }
const _hoisted_8 = { key: 1 }
const _hoisted_9 = { class: "wizardStep" }
const _hoisted_10 = { class: "mb-3" }
const _hoisted_11 = { class: "mt-3" }
const _hoisted_12 = { class: "wizardStep" }
const _hoisted_13 = { class: "mb-3" }
const _hoisted_14 = { class: "timer-control d-flex justify-content-center mb-5" }
const _hoisted_15 = {
  id: "setting-value",
  class: "font-weight-bolder px-3 text-center"
}
const _hoisted_16 = { class: "mb-3" }
const _hoisted_17 = { class: "voting-control d-flex justify-content-center mb-2" }
const _hoisted_18 = { class: "wizardStep" }
const _hoisted_19 = { class: "mb-3" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_b_img = _resolveComponent("b-img")!
  const _component_story_points_component = _resolveComponent("story-points-component")!
  const _component_user_story_component = _resolveComponent("user-story-component")!
  const _component_b_button = _resolveComponent("b-button")!
  const _component_jira_component = _resolveComponent("jira-component")!
  const _component_card_set_component = _resolveComponent("card-set-component")!
  const _component_b_form_input = _resolveComponent("b-form-input")!
  const _component_b_col = _resolveComponent("b-col")!
  const _component_b_row = _resolveComponent("b-row")!
  const _component_b_list_group_item = _resolveComponent("b-list-group-item")!
  const _component_b_list_group = _resolveComponent("b-list-group")!
  const _component_Steppy = _resolveComponent("Steppy")!
  const _component_b_container = _resolveComponent("b-container")!

  return (_openBlock(), _createBlock(_component_b_container, { class: "main" }, {
    default: _withCtx(() => [
      _createElementVNode("h1", null, _toDisplayString(_ctx.t("session.prepare.title")), 1),
      _createVNode(_component_Steppy, {
        step: _ctx.step,
        "onUpdate:step": _cache[11] || (_cache[11] = ($event: any) => ((_ctx.step) = $event)),
        finalize: _ctx.sendCreateSessionRequest,
        tabs: _ctx.tabs,
        "circle-size": 40,
        "primary-color1": 'var(--preparePage-wizard-status)',
        "primary-color2": 'var(--preparePage-wizard-statusbackground)',
        "background-color": 'var(--preparePage-wizard-background)',
        "back-text": _ctx.t('session.prepare.step.wizard.wizardBack'),
        "next-text": _ctx.t('session.prepare.step.wizard.wizardNext'),
        "done-text": _ctx.t('session.prepare.step.wizard.wizardDone')
      }, {
        "1": _withCtx(() => [
          _createElementVNode("div", _hoisted_1, [
            _createElementVNode("h4", _hoisted_2, [
              (_ctx.theme === 'light')
                ? (_openBlock(), _createBlock(_component_b_img, {
                    key: 0,
                    src: require('@/assets/preparePage/P1.png'),
                    class: "numberPictures"
                  }, null, 8, ["src"]))
                : (_openBlock(), _createBlock(_component_b_img, {
                    key: 1,
                    src: require('@/assets/preparePage/P1D.png'),
                    class: "numberPictures"
                  }, null, 8, ["src"])),
              _createTextVNode(" " + _toDisplayString(_ctx.t("session.prepare.step.selection.mode.title")), 1)
            ]),
            _createElementVNode("div", _hoisted_3, [
              _createElementVNode("button", {
                type: "button",
                class: _normalizeClass(['mode-icon', _ctx.tabIndex === 0 ? 'active' : '']),
                onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.setTabIndex(0)))
              }, [
                _createVNode(_component_b_img, {
                  src: require('@/assets/preparePage/Mode1.png'),
                  class: _normalizeClass(["modeIconImage", { active: _ctx.tabIndex === 0 }])
                }, null, 8, ["src", "class"]),
                _createElementVNode("span", _hoisted_4, _toDisplayString(_ctx.t("session.prepare.step.selection.mode.description.withoutUS.tab.label")), 1)
              ], 2),
              _createElementVNode("button", {
                type: "button",
                class: _normalizeClass(['mode-icon', _ctx.tabIndex === 1 ? 'active' : '']),
                onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.setTabIndex(1)))
              }, [
                _createVNode(_component_b_img, {
                  src: require('@/assets/preparePage/Mode2.png'),
                  class: _normalizeClass(["modeIconImage", { active: _ctx.tabIndex === 1 }])
                }, null, 8, ["src", "class"]),
                _createElementVNode("span", _hoisted_5, _toDisplayString(_ctx.t("session.prepare.step.selection.mode.description.withUS.tab.label")), 1)
              ], 2),
              (_ctx.isIssueTrackerEnabled)
                ? (_openBlock(), _createElementBlock("button", {
                    key: 0,
                    type: "button",
                    class: _normalizeClass(['mode-icon', _ctx.tabIndex === 2 ? 'active' : '']),
                    onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.setTabIndex(2)))
                  }, [
                    _createVNode(_component_b_img, {
                      src: require('@/assets/preparePage/Mode3.png'),
                      class: _normalizeClass(["modeIconImage", { active: _ctx.tabIndex === 2 }])
                    }, null, 8, ["src", "class"]),
                    _createElementVNode("span", _hoisted_6, _toDisplayString(_ctx.t("session.prepare.step.selection.mode.description.withIssueTracker.tab.label")), 1)
                  ], 2))
                : _createCommentVNode("", true)
            ]),
            _createElementVNode("div", _hoisted_7, [
              (_ctx.tabIndex === 0)
                ? (_openBlock(), _createBlock(_component_story_points_component, { key: 0 }))
                : (_ctx.tabIndex === 1)
                  ? (_openBlock(), _createElementBlock("div", _hoisted_8, [
                      _createVNode(_component_user_story_component, { class: "mt-5" }),
                      _createElementVNode("input", {
                        id: "fileUpload",
                        type: "file",
                        hidden: "",
                        accept: "text/csv",
                        onChange: _cache[3] || (_cache[3] = ($event: any) => (_ctx.importStory($event)))
                      }, null, 32),
                      _createVNode(_component_b_button, {
                        block: "",
                        elevation: "2",
                        class: "btn-primary",
                        variant: "primary",
                        onClick: _cache[4] || (_cache[4] = ($event: any) => {
                  _ctx.openFileUploader();
                  $event.target.blur();
                })
                      }, {
                        default: _withCtx(() => [
                          _createTextVNode(_toDisplayString(_ctx.t("session.prepare.step.selection.mode.description.withUS.importButton")), 1)
                        ], undefined, true),
                        _: 1
                      })
                    ]))
                  : (_ctx.tabIndex === 2)
                    ? (_openBlock(), _createBlock(_component_jira_component, {
                        key: 2,
                        class: "mt-5",
                        onJira: _cache[5] || (_cache[5] = ($event: any) => (_ctx.isJiraSelected = true))
                      }))
                    : _createCommentVNode("", true)
            ])
          ])
        ]),
        "2": _withCtx(() => [
          _createElementVNode("div", _hoisted_9, [
            _createElementVNode("h4", _hoisted_10, [
              (_ctx.theme === 'light')
                ? (_openBlock(), _createBlock(_component_b_img, {
                    key: 0,
                    src: require('@/assets/preparePage/P2.png'),
                    class: "numberPictures"
                  }, null, 8, ["src"]))
                : (_openBlock(), _createBlock(_component_b_img, {
                    key: 1,
                    src: require('@/assets/preparePage/P2D.png'),
                    class: "numberPictures"
                  }, null, 8, ["src"])),
              _createTextVNode(" " + _toDisplayString(_ctx.t("session.prepare.step.selection.cardSet.title")), 1)
            ]),
            _createVNode(_component_card_set_component, {
              class: "mt-2",
              "user-story-mode": _ctx.userStoryMode,
              "selected-card-set": _ctx.selectedCardSetOptions,
              onSelectedCardSetOptions: _ctx.setCardSetOptions
            }, null, 8, ["user-story-mode", "selected-card-set", "onSelectedCardSetOptions"]),
            _createElementVNode("h4", _hoisted_11, _toDisplayString(_ctx.t("session.prepare.step.selection.password.title")), 1),
            _createVNode(_component_b_row, { class: "mt-1" }, {
              default: _withCtx(() => [
                _createVNode(_component_b_col, null, {
                  default: _withCtx(() => [
                    _createVNode(_component_b_form_input, {
                      id: "input-password",
                      modelValue: _ctx.password,
                      "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.password) = $event)),
                      placeholder: _ctx.t('session.prepare.step.selection.password.placeholder')
                    }, null, 8, ["modelValue", "placeholder"])
                  ], undefined, true),
                  _: 1
                })
              ], undefined, true),
              _: 1
            })
          ])
        ]),
        "3": _withCtx(() => [
          _createElementVNode("div", _hoisted_12, [
            _createElementVNode("h4", _hoisted_13, [
              (_ctx.theme === 'light')
                ? (_openBlock(), _createBlock(_component_b_img, {
                    key: 0,
                    src: require('@/assets/preparePage/P3.png'),
                    class: "numberPictures"
                  }, null, 8, ["src"]))
                : (_openBlock(), _createBlock(_component_b_img, {
                    key: 1,
                    src: require('@/assets/preparePage/P3D.png'),
                    class: "numberPictures"
                  }, null, 8, ["src"])),
              _createTextVNode(" " + _toDisplayString(_ctx.t("session.prepare.step.selection.time.title")), 1)
            ]),
            _createElementVNode("div", _hoisted_14, [
              _createVNode(_component_b_button, {
                variant: "primary",
                onClick: _cache[7] || (_cache[7] = ($event: any) => {
                _ctx.setTimerDown();
                $event.target.blur();
              })
              }, {
                default: _withCtx(() => _cache[12] || (_cache[12] = [
                  _createTextVNode(" - ")
                ]), undefined, true),
                _: 1
              }),
              _createElementVNode("div", _hoisted_15, _toDisplayString(_ctx.timer == 0 ? "∞" : _ctx.formatTimer), 1),
              _createVNode(_component_b_button, {
                variant: "primary",
                onClick: _cache[8] || (_cache[8] = ($event: any) => {
                _ctx.setTimerUp();
                $event.target.blur();
              })
              }, {
                default: _withCtx(() => _cache[13] || (_cache[13] = [
                  _createTextVNode(" + ")
                ]), undefined, true),
                _: 1
              })
            ]),
            _createElementVNode("h4", _hoisted_16, [
              (_ctx.theme === 'light')
                ? (_openBlock(), _createBlock(_component_b_img, {
                    key: 0,
                    src: require('@/assets/preparePage/P4.png'),
                    class: "numberPictures"
                  }, null, 8, ["src"]))
                : (_openBlock(), _createBlock(_component_b_img, {
                    key: 1,
                    src: require('@/assets/preparePage/P4D.png'),
                    class: "numberPictures"
                  }, null, 8, ["src"])),
              _createTextVNode(" " + _toDisplayString(_ctx.t("session.prepare.step.selection.hostVoting.title")), 1)
            ]),
            _createVNode(_component_b_row, { class: "mt-2" }, {
              default: _withCtx(() => [
                _createVNode(_component_b_col, null, {
                  default: _withCtx(() => [
                    _createElementVNode("div", _hoisted_17, [
                      _createVNode(_component_b_button, {
                        variant: _ctx.hostVoting ? 'primary' : 'outline-light',
                        onClick: _cache[9] || (_cache[9] = ($event: any) => {
                    _ctx.hostVoting = true;
                    $event.target.blur();
                  })
                      }, {
                        default: _withCtx(() => [
                          _createTextVNode(_toDisplayString(_ctx.t("session.prepare.step.selection.hostVoting.hostVotingOn")), 1)
                        ], undefined, true),
                        _: 1
                      }, 8, ["variant"]),
                      _createVNode(_component_b_button, {
                        variant: !_ctx.hostVoting ? 'primary' : 'outline-light',
                        onClick: _cache[10] || (_cache[10] = ($event: any) => {
                    _ctx.hostVoting = false;
                    $event.target.blur();
                  })
                      }, {
                        default: _withCtx(() => [
                          _createTextVNode(_toDisplayString(_ctx.t("session.prepare.step.selection.hostVoting.hostVotingOff")), 1)
                        ], undefined, true),
                        _: 1
                      }, 8, ["variant"])
                    ])
                  ], undefined, true),
                  _: 1
                })
              ], undefined, true),
              _: 1
            })
          ])
        ]),
        "4": _withCtx(() => [
          _createElementVNode("div", _hoisted_18, [
            _createElementVNode("h4", _hoisted_19, _toDisplayString(_ctx.t("session.prepare.step.confirmation.title")), 1),
            _createVNode(_component_b_list_group, null, {
              default: _withCtx(() => [
                _createVNode(_component_b_list_group_item, null, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(_ctx.t("session.prepare.step.selection.mode.title")) + ": " + _toDisplayString(_ctx.userStoryMode), 1)
                  ], undefined, true),
                  _: 1
                }),
                _createVNode(_component_b_list_group_item, null, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(_ctx.t("session.prepare.step.selection.cardSet.title")) + ": " + _toDisplayString(Array.isArray(_ctx.selectedCardSetOptions.activeValues)
                  ? _ctx.selectedCardSetOptions.activeValues.join(", ")
                  : ""), 1)
                  ], undefined, true),
                  _: 1
                }),
                _createVNode(_component_b_list_group_item, null, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(_ctx.t("session.prepare.step.selection.time.title")) + ": " + _toDisplayString(_ctx.timer == 0 ? "∞" : _ctx.formatTimer), 1)
                  ], undefined, true),
                  _: 1
                }),
                _createVNode(_component_b_list_group_item, null, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(_ctx.t("session.prepare.step.selection.hostVoting.title")) + ": " + _toDisplayString(_ctx.hostVoting
                  ? _ctx.t("session.prepare.step.selection.hostVoting.hostVotingOn")
                  : _ctx.t("session.prepare.step.selection.hostVoting.hostVotingOff")), 1)
                  ], undefined, true),
                  _: 1
                }),
                _createVNode(_component_b_list_group_item, null, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(_ctx.t("session.prepare.step.selection.password.title")) + ": " + _toDisplayString(_ctx.password), 1)
                  ], undefined, true),
                  _: 1
                })
              ], undefined, true),
              _: 1
            })
          ])
        ]),
        _: 1
      }, 8, ["step", "finalize", "tabs", "back-text", "next-text", "done-text"])
    ], undefined, true),
    _: 1
  }))
}