import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_b_button = _resolveComponent("b-button")!

  return (_openBlock(), _createBlock(_component_b_button, {
    variant: "primary",
    size: "lg",
    class: "my-5",
    disabled: !_ctx.members || _ctx.members.length < 1,
    onClick: _ctx.sendStartEstimationMessages
  }, {
    default: _withCtx(() => [
      _createTextVNode(_toDisplayString(_ctx.t("page.session.before.button")), 1)
    ], undefined, true),
    _: 1
  }, 8, ["disabled", "onClick"]))
}