import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, resolveComponent as _resolveComponent, createBlock as _createBlock, createElementVNode as _createElementVNode, createVNode as _createVNode, createTextVNode as _createTextVNode, withCtx as _withCtx } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "text-center"
}
const _hoisted_2 = {
  key: 1,
  class: "text-center"
}
const _hoisted_3 = { id: "b-modal-body" }
const _hoisted_4 = {
  id: "aiOptions",
  class: "text-center mt-1"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_b_spinner = _resolveComponent("b-spinner")!
  const _component_UiToastEditorWrapper = _resolveComponent("UiToastEditorWrapper")!
  const _component_b_icon_check2 = _resolveComponent("b-icon-check2")!
  const _component_b_button = _resolveComponent("b-button")!
  const _component_b_icon_sliders = _resolveComponent("b-icon-sliders")!
  const _component_b_icon_arrow_repeat = _resolveComponent("b-icon-arrow-repeat")!
  const _component_b_icon_backspace = _resolveComponent("b-icon-backspace")!
  const _component_b_icon_x_square = _resolveComponent("b-icon-x-square")!
  const _component_b_modal = _resolveComponent("b-modal")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(_component_b_modal, {
      id: "gptModal",
      modelValue: _ctx.showModal,
      "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.showModal) = $event)),
      centered: "",
      "hide-header-close": "",
      onHide: _cache[4] || (_cache[4] = ($event: any) => (_ctx.closeModal()))
    }, {
      "modal-header": _withCtx(() => [
        (_ctx.gptMode === 'improveDescription')
          ? (_openBlock(), _createElementBlock("div", _hoisted_1, " Alternative Description> "))
          : (_openBlock(), _createElementBlock("div", _hoisted_2, _toDisplayString(_ctx.gptMode === "grammar" ? "Corrected Description" : "Marked Description"), 1))
      ]),
      "modal-footer": _withCtx(() => [
        _createElementVNode("div", _hoisted_4, [
          _createVNode(_component_b_button, {
            id: "acceptAIOption",
            class: "m-1",
            onClick: _ctx.acceptDescription
          }, {
            default: _withCtx(() => [
              _createVNode(_component_b_icon_check2),
              _cache[5] || (_cache[5] = _createTextVNode(" Keep "))
            ], undefined, true),
            _: 1
          }, 8, ["onClick"]),
          (_ctx.showOtherOptionButtons)
            ? (_openBlock(), _createBlock(_component_b_button, {
                key: 0,
                class: "aiOptionButtons m-1",
                onClick: _cache[0] || (_cache[0] = ($event: any) => {
              _ctx.adjustDescription();
              $event.target.blur();
            })
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_b_icon_sliders),
                  _cache[6] || (_cache[6] = _createTextVNode(" Adjust "))
                ], undefined, true),
                _: 1
              }))
            : _createCommentVNode("", true),
          (_ctx.showOtherOptionButtons)
            ? (_openBlock(), _createBlock(_component_b_button, {
                key: 1,
                class: "aiOptionButtons m-1",
                onClick: _cache[1] || (_cache[1] = ($event: any) => {
              _ctx.retryDescription();
              $event.target.blur();
            })
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_b_icon_arrow_repeat),
                  _cache[7] || (_cache[7] = _createTextVNode(" Try Again "))
                ], undefined, true),
                _: 1
              }))
            : _createCommentVNode("", true),
          (_ctx.showOtherOptionButtons)
            ? (_openBlock(), _createBlock(_component_b_button, {
                key: 2,
                class: "aiOptionButtons m-1",
                onClick: _ctx.deleteDescription
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_b_icon_backspace),
                  _cache[8] || (_cache[8] = _createTextVNode(" Delete "))
                ], undefined, true),
                _: 1
              }, 8, ["onClick"]))
            : _createCommentVNode("", true),
          (!_ctx.showOtherOptionButtons)
            ? (_openBlock(), _createBlock(_component_b_button, {
                key: 3,
                class: "aiOptionButtons m-1",
                onClick: _cache[2] || (_cache[2] = ($event: any) => {
              _ctx.cancelAdjust();
              $event.target.blur();
            })
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_b_icon_x_square),
                  _cache[9] || (_cache[9] = _createTextVNode(" Cancel "))
                ], undefined, true),
                _: 1
              }))
            : _createCommentVNode("", true)
        ])
      ]),
      default: _withCtx(() => [
        _createElementVNode("div", _hoisted_3, [
          (_ctx.showSpinner)
            ? (_openBlock(), _createBlock(_component_b_spinner, {
                key: 0,
                variant: "primary",
                class: "position-absolute centerSpinner"
              }))
            : _createCommentVNode("", true),
          (!_ctx.showSpinner)
            ? (_openBlock(), _createBlock(_component_UiToastEditorWrapper, {
                key: _ctx.repaint,
                "initial-value": _ctx.suggestionDescription,
                "none-clickable": !_ctx.clickable,
                onStillTyping: _ctx.copyText
              }, null, 8, ["initial-value", "none-clickable", "onStillTyping"]))
            : _createCommentVNode("", true)
        ])
      ], undefined, true),
      _: 1
    }, 8, ["modelValue"])
  ]))
}