import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, vModelRadio as _vModelRadio, withDirectives as _withDirectives } from "vue"

const _hoisted_1 = { id: "privacyModal" }
const _hoisted_2 = { class: "text-center" }
const _hoisted_3 = { id: "warningText" }
const _hoisted_4 = { class: "d-flex justify-content-between" }
const _hoisted_5 = { class: "d-flex justify-content-between mt-3" }
const _hoisted_6 = { class: "confidential-words-div" }
const _hoisted_7 = ["onMouseover"]
const _hoisted_8 = { class: "confidential-words-div" }
const _hoisted_9 = ["onMouseover"]
const _hoisted_10 = { class: "confidential-words-div" }
const _hoisted_11 = ["onMouseover"]
const _hoisted_12 = {
  key: 0,
  class: "my-2 d-inline-flex"
}
const _hoisted_13 = { class: "position-relative mb-2" }
const _hoisted_14 = {
  id: "english-label",
  for: "english",
  class: "position-absolute"
}
const _hoisted_15 = { class: "position-relative mb-2" }
const _hoisted_16 = {
  id: "german-label",
  for: "german",
  class: "position-absolute"
}
const _hoisted_17 = {
  id: "aiOptions",
  class: "text-center mt-1"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_BIconExclamationTriangle = _resolveComponent("BIconExclamationTriangle")!
  const _component_b_form_textarea = _resolveComponent("b-form-textarea")!
  const _component_UiToastEditorWrapper = _resolveComponent("UiToastEditorWrapper")!
  const _component_BIconHouseDoorFill = _resolveComponent("BIconHouseDoorFill")!
  const _component_b_button = _resolveComponent("b-button")!
  const _component_BIconPersonFill = _resolveComponent("BIconPersonFill")!
  const _component_BIconCalculatorFill = _resolveComponent("BIconCalculatorFill")!
  const _component_BIconHouseFill = _resolveComponent("BIconHouseFill")!
  const _component_b_icon_trash = _resolveComponent("b-icon-trash")!
  const _component_b_icon_check2 = _resolveComponent("b-icon-check2")!
  const _component_b_icon_x_square = _resolveComponent("b-icon-x-square")!
  const _component_b_modal = _resolveComponent("b-modal")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_b_modal, {
      modelValue: _ctx.showModal,
      "onUpdate:modelValue": _cache[10] || (_cache[10] = ($event: any) => ((_ctx.showModal) = $event)),
      centered: "",
      "hide-header-close": "",
      onHide: _ctx.hideModal
    }, {
      "modal-header": _withCtx(() => [
        _createElementVNode("div", _hoisted_2, _toDisplayString(_ctx.t("general.aiFeature.privacyModal.title")), 1)
      ]),
      "modal-footer": _withCtx(() => [
        _createElementVNode("div", _hoisted_17, [
          _createVNode(_component_b_button, {
            id: "acceptAIOption",
            class: "m-1",
            onClick: _ctx.submitIssue
          }, {
            default: _withCtx(() => [
              _createVNode(_component_b_icon_check2),
              _createTextVNode(" " + _toDisplayString(_ctx.t("general.aiFeature.privacyModal.buttons.ok")), 1)
            ], undefined, true),
            _: 1
          }, 8, ["onClick"]),
          _createVNode(_component_b_button, {
            class: "aiOptionButtons m-1",
            onClick: _cache[9] || (_cache[9] = ($event: any) => {
              _ctx.hideModal();
              $event.target.blur();
            })
          }, {
            default: _withCtx(() => [
              _createVNode(_component_b_icon_x_square),
              _createTextVNode(" " + _toDisplayString(_ctx.t("general.aiFeature.privacyModal.buttons.cancel")), 1)
            ], undefined, true),
            _: 1
          })
        ])
      ]),
      default: _withCtx(() => [
        _createElementVNode("div", _hoisted_3, [
          _createVNode(_component_BIconExclamationTriangle),
          _createTextVNode(" " + _toDisplayString(_ctx.t("general.aiFeature.privacyModal.warning")) + ": " + _toDisplayString(_ctx.t("general.aiFeature.privacyModal.warningInfo")), 1)
        ]),
        _createElementVNode("div", null, [
          (_ctx.currentTitle !== '')
            ? (_openBlock(), _createBlock(_component_b_form_textarea, {
                key: 0,
                id: "titleInputField",
                modelValue: _ctx.currentTitleData,
                "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.currentTitleData) = $event)),
                class: "mt-2",
                disabled: ""
              }, null, 8, ["modelValue"]))
            : _createCommentVNode("", true),
          _createVNode(_component_UiToastEditorWrapper, {
            "initial-value": _ctx.currentText,
            "none-clickable": true
          }, null, 8, ["initial-value"]),
          _createElementVNode("div", _hoisted_4, [
            _createVNode(_component_b_button, {
              class: "toggle-button",
              onClick: _cache[1] || (_cache[1] = ($event: any) => {
              _ctx.addWordToList('company');
              $event.target.blur();
            })
            }, {
              default: _withCtx(() => [
                _createVNode(_component_BIconHouseDoorFill),
                _createTextVNode(" " + _toDisplayString(_ctx.t("general.aiFeature.privacyModal.buttons.company")), 1)
              ], undefined, true),
              _: 1
            }),
            _createVNode(_component_b_button, {
              class: "toggle-button",
              onClick: _cache[2] || (_cache[2] = ($event: any) => {
              _ctx.addWordToList('person');
              $event.target.blur();
            })
            }, {
              default: _withCtx(() => [
                _createVNode(_component_BIconPersonFill),
                _createTextVNode(" " + _toDisplayString(_ctx.t("general.aiFeature.privacyModal.buttons.person")), 1)
              ], undefined, true),
              _: 1
            }),
            _createVNode(_component_b_button, {
              class: "toggle-button",
              onClick: _cache[3] || (_cache[3] = ($event: any) => {
              _ctx.addWordToList('number');
              $event.target.blur();
            })
            }, {
              default: _withCtx(() => [
                _createVNode(_component_BIconCalculatorFill),
                _createTextVNode(" " + _toDisplayString(_ctx.t("general.aiFeature.privacyModal.buttons.number")), 1)
              ], undefined, true),
              _: 1
            })
          ]),
          _createElementVNode("div", _hoisted_5, [
            _createElementVNode("div", _hoisted_6, [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.confidentialWords.keys(), (data, index) => {
                return (_openBlock(), _createElementBlock("div", { key: data }, [
                  (_ctx.confidentialWords.get(data) === 'company')
                    ? (_openBlock(), _createElementBlock("div", {
                        key: 0,
                        onMouseover: ($event: any) => (_ctx.hover = index),
                        onMouseleave: _cache[4] || (_cache[4] = ($event: any) => (_ctx.hover = null))
                      }, [
                        _createVNode(_component_BIconHouseFill),
                        _createTextVNode(" " + _toDisplayString(data) + " ", 1),
                        _createVNode(_component_b_button, {
                          variant: "outline-danger",
                          class: "border-0",
                          size: "sm",
                          onClick: ($event: any) => {
                    _ctx.confidentialWords.delete(data);
                    _ctx.caseSensitiveList = _ctx.caseSensitiveList.filter(
                      (word) => word !== data.toLowerCase()
                    );
                  }
                        }, {
                          default: _withCtx(() => [
                            _createVNode(_component_b_icon_trash)
                          ], undefined, true),
                          _: 2
                        }, 1032, ["onClick"])
                      ], 40, _hoisted_7))
                    : _createCommentVNode("", true)
                ]))
              }), 128))
            ]),
            _createElementVNode("div", _hoisted_8, [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.confidentialWords.keys(), (data, index) => {
                return (_openBlock(), _createElementBlock("div", { key: data }, [
                  (_ctx.confidentialWords.get(data) === 'person')
                    ? (_openBlock(), _createElementBlock("div", {
                        key: 0,
                        class: "mx-2",
                        onMouseover: ($event: any) => (_ctx.hover = index),
                        onMouseleave: _cache[5] || (_cache[5] = ($event: any) => (_ctx.hover = null))
                      }, [
                        _createVNode(_component_BIconPersonFill),
                        _createTextVNode(" " + _toDisplayString(data) + " ", 1),
                        _createVNode(_component_b_button, {
                          variant: "outline-danger",
                          class: "border-0",
                          size: "sm",
                          onClick: ($event: any) => {
                    _ctx.confidentialWords.delete(data);
                    _ctx.caseSensitiveList = _ctx.caseSensitiveList.filter(
                      (word) => word !== data.toLowerCase()
                    );
                  }
                        }, {
                          default: _withCtx(() => [
                            _createVNode(_component_b_icon_trash)
                          ], undefined, true),
                          _: 2
                        }, 1032, ["onClick"])
                      ], 40, _hoisted_9))
                    : _createCommentVNode("", true)
                ]))
              }), 128))
            ]),
            _createElementVNode("div", _hoisted_10, [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.confidentialWords.keys(), (data, index) => {
                return (_openBlock(), _createElementBlock("div", { key: data }, [
                  (_ctx.confidentialWords.get(data) === 'number')
                    ? (_openBlock(), _createElementBlock("div", {
                        key: 0,
                        class: "mx-2",
                        onMouseover: ($event: any) => (_ctx.hover = index),
                        onMouseleave: _cache[6] || (_cache[6] = ($event: any) => (_ctx.hover = null))
                      }, [
                        _createVNode(_component_BIconCalculatorFill),
                        _createTextVNode(" " + _toDisplayString(data) + " ", 1),
                        _createVNode(_component_b_button, {
                          variant: "outline-danger",
                          class: "border-0",
                          size: "sm",
                          onClick: ($event: any) => {
                    _ctx.confidentialWords.delete(data);
                    _ctx.caseSensitiveList = _ctx.caseSensitiveList.filter(
                      (word) => word !== data.toLowerCase()
                    );
                  }
                        }, {
                          default: _withCtx(() => [
                            _createVNode(_component_b_icon_trash)
                          ], undefined, true),
                          _: 2
                        }, 1032, ["onClick"])
                      ], 40, _hoisted_11))
                    : _createCommentVNode("", true)
                ]))
              }), 128))
            ])
          ])
        ]),
        (_ctx.isDescription)
          ? (_openBlock(), _createElementBlock("div", _hoisted_12, [
              _createElementVNode("div", _hoisted_13, [
                _withDirectives(_createElementVNode("input", {
                  id: "english-radio-button",
                  "onUpdate:modelValue": _cache[7] || (_cache[7] = ($event: any) => ((_ctx.selectedLanguage) = $event)),
                  class: "position-absolute",
                  type: "radio",
                  name: "languageSelector",
                  value: "english"
                }, null, 512), [
                  [_vModelRadio, _ctx.selectedLanguage]
                ]),
                _createElementVNode("label", _hoisted_14, _toDisplayString(_ctx.t("general.aiFeature.privacyModal.labels.english")), 1)
              ]),
              _createElementVNode("div", _hoisted_15, [
                _withDirectives(_createElementVNode("input", {
                  id: "german-radio-button",
                  "onUpdate:modelValue": _cache[8] || (_cache[8] = ($event: any) => ((_ctx.selectedLanguage) = $event)),
                  class: "position-absolute",
                  type: "radio",
                  name: "languageSelector",
                  value: "german"
                }, null, 512), [
                  [_vModelRadio, _ctx.selectedLanguage]
                ]),
                _createElementVNode("label", _hoisted_16, _toDisplayString(_ctx.t("general.aiFeature.privacyModal.labels.german")), 1)
              ])
            ]))
          : _createCommentVNode("", true)
      ], undefined, true),
      _: 1
    }, 8, ["modelValue", "onHide"])
  ]))
}