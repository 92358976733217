import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = { class: "fs-3-rem" }
const _hoisted_2 = { key: 0 }
const _hoisted_3 = { key: 1 }
const _hoisted_4 = { key: 2 }
const _hoisted_5 = {
  class: "rounded-avatar rounded-circle",
  style: 'background-color: #30a444;'
}
const _hoisted_6 = {
  id: 'avatar',
  class: "text-center"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_b_img = _resolveComponent("b-img")!

  return (_openBlock(), _createElementBlock("div", {
    style: 'background-color: #30a444;',
    class: _normalizeClass(["session-member-card text-center card m-2", { greyOut: _ctx.highlight === false && _ctx.estimateFinished }])
  }, [
    _createElementVNode("h1", _hoisted_1, [
      (!_ctx.estimateFinished)
        ? (_openBlock(), _createElementBlock("strong", _hoisted_2, "?"))
        : _createCommentVNode("", true),
      (_ctx.currentEstimation === '' && _ctx.estimateFinished)
        ? (_openBlock(), _createElementBlock("strong", _hoisted_3, "-"))
        : _createCommentVNode("", true),
      (_ctx.currentEstimation !== null && _ctx.estimateFinished)
        ? (_openBlock(), _createElementBlock("strong", _hoisted_4, _toDisplayString(_ctx.currentEstimation), 1))
        : _createCommentVNode("", true)
    ]),
    _createElementVNode("div", _hoisted_5, [
      _createElementVNode("div", _hoisted_6, [
        _createVNode(_component_b_img, {
          src: require(`@/assets/crown.png`),
          class: "rounded-avatar__image"
        }, null, 8, ["src"]),
        _cache[0] || (_cache[0] = _createElementVNode("div", { class: "rounded-avatar__label" }, [
          _createElementVNode("b", { id: "hostName" }, "Host")
        ], -1))
      ])
    ])
  ], 2))
}