import { toDisplayString as _toDisplayString, resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, createElementVNode as _createElementVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { id: "heading" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_BIconController = _resolveComponent("BIconController")!
  const _component_join_page_card = _resolveComponent("join-page-card")!
  const _component_b_container = _resolveComponent("b-container")!

  return (_openBlock(), _createBlock(_component_b_container, null, {
    default: _withCtx(() => [
      _createElementVNode("h1", _hoisted_1, [
        _createTextVNode(_toDisplayString(_ctx.t("page.join.title")) + " ", 1),
        _createVNode(_component_BIconController, { id: "controller" })
      ]),
      _createVNode(_component_join_page_card, {
        color: _ctx.hexColor,
        "animal-asset-name": _ctx.avatarAnimalAssetName,
        "button-text": _ctx.t('page.join.submit'),
        "session-id-from-url": _ctx.sessionID,
        onClicked: _ctx.sendJoinSessionRequest
      }, null, 8, ["color", "animal-asset-name", "button-text", "session-id-from-url", "onClicked"])
    ], undefined, true),
    _: 1
  }))
}