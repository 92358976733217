import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, normalizeStyle as _normalizeStyle, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_rounded_avatar = _resolveComponent("rounded-avatar")!
  const _component_b_row = _resolveComponent("b-row")!
  const _component_b_form_input = _resolveComponent("b-form-input")!
  const _component_b_col = _resolveComponent("b-col")!
  const _component_b_button = _resolveComponent("b-button")!

  return (_openBlock(), _createBlock(_component_b_row, { ref: "card" }, {
    default: _withCtx(() => [
      _cache[3] || (_cache[3] = _createElementVNode("div", { class: "col-lg-3 col-md-2 col-sm-1" }, null, -1)),
      _createElementVNode("div", {
        id: "join-card",
        style: _normalizeStyle(`background-color: ${_ctx.color};`),
        class: "card col-lg-6 col-md-8 col-sm-10 p-5"
      }, [
        _createVNode(_component_b_row, { class: "justify-content-center" }, {
          default: _withCtx(() => [
            _createVNode(_component_rounded_avatar, {
              member: {
            hexColor: 'transparent',
            avatarAnimal: _ctx.animalAssetName,
            name: '',
          },
              "show-name": false
            }, null, 8, ["member"])
          ], undefined, true),
          _: 1
        }),
        _createVNode(_component_b_row, { class: "mt-2" }, {
          default: _withCtx(() => [
            _createVNode(_component_b_col, { class: "inputText" }, {
              default: _withCtx(() => [
                _createElementVNode("h6", null, _toDisplayString(_ctx.t("page.join.input.name")), 1),
                _createVNode(_component_b_form_input, {
                  ref: "name",
                  modelValue: _ctx.name,
                  "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.name) = $event)),
                  class: "mt-3 inputFields",
                  type: "text"
                }, null, 8, ["modelValue"])
              ], undefined, true),
              _: 1
            })
          ], undefined, true),
          _: 1
        }),
        _createVNode(_component_b_row, { class: "mt-4" }, {
          default: _withCtx(() => [
            _createVNode(_component_b_col, {
              cols: "12",
              md: '10',
              class: "inputText"
            }, {
              default: _withCtx(() => [
                _createElementVNode("h6", null, _toDisplayString(_ctx.t("page.join.input.code")), 1),
                _createVNode(_component_b_form_input, {
                  modelValue: _ctx.sessionID,
                  "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.sessionID) = $event)),
                  class: "mt-3 inputFields",
                  type: "text"
                }, null, 8, ["modelValue"])
              ], undefined, true),
              _: 1
            })
          ], undefined, true),
          _: 1
        }),
        _createVNode(_component_b_row, null, {
          default: _withCtx(() => [
            _createVNode(_component_b_col, {
              class: "mt-4 inputText",
              cols: "12",
              md: "9"
            }, {
              default: _withCtx(() => [
                _createElementVNode("h6", null, _toDisplayString(_ctx.t("page.join.input.password")), 1),
                _createVNode(_component_b_form_input, {
                  modelValue: _ctx.password,
                  "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.password) = $event)),
                  class: "mt-3 inputFields",
                  type: "password",
                  placeholder: "(optional)"
                }, null, 8, ["modelValue"])
              ], undefined, true),
              _: 1
            }),
            _createVNode(_component_b_col, {
              id: "startButtonCol",
              cols: "12",
              md: "3"
            }, {
              default: _withCtx(() => [
                _createVNode(_component_b_button, {
                  class: "rounded-circle startingButton",
                  type: "submit",
                  disabled: _ctx.name.length < 1 || _ctx.sessionID.length < 1,
                  onClick: _ctx.onClickButton
                }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(_ctx.buttonText), 1)
                  ], undefined, true),
                  _: 1
                }, 8, ["disabled", "onClick"])
              ], undefined, true),
              _: 1
            })
          ], undefined, true),
          _: 1
        })
      ], 4),
      _cache[4] || (_cache[4] = _createElementVNode("div", { class: "col-lg-3 col-md-2 col-sm-1" }, null, -1)),
      _createVNode(_component_b_col)
    ], undefined, true),
    _: 1
  }, 512))
}