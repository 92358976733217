import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_b_card_text = _resolveComponent("b-card-text")!
  const _component_b_button = _resolveComponent("b-button")!
  const _component_b_card = _resolveComponent("b-card")!

  return (_openBlock(), _createBlock(_component_b_card, {
    class: "landing-page-card text-center",
    title: _ctx.title
  }, {
    footer: _withCtx(() => [
      _createVNode(_component_b_button, {
        variant: _ctx.buttonVariant,
        class: "landingPageCardButton",
        onClick: _ctx.onClick
      }, {
        default: _withCtx(() => [
          _createTextVNode(_toDisplayString(_ctx.buttonText), 1)
        ], undefined, true),
        _: 1
      }, 8, ["variant", "onClick"])
    ]),
    default: _withCtx(() => [
      _createVNode(_component_b_card_text, { class: "my-5" }, {
        default: _withCtx(() => [
          _createTextVNode(_toDisplayString(_ctx.description), 1)
        ], undefined, true),
        _: 1
      })
    ], undefined, true),
    _: 1
  }, 8, ["title"]))
}