import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "feedback" }
const _hoisted_2 = {
  id: "feedback-btn",
  target: "_blank",
  href: "https://github.com/Sybit-Education/Diveni/issues/new/choose"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("a", _hoisted_2, _toDisplayString(_ctx.t("general.feedback")), 1)
  ]))
}