import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, normalizeClass as _normalizeClass, createBlock as _createBlock, vShow as _vShow, withDirectives as _withDirectives, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, normalizeStyle as _normalizeStyle } from "vue"

const _hoisted_1 = { class: "user-stories" }
const _hoisted_2 = {
  key: 0,
  class: "w-100 d-flex justify-content-left"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_BIconSearch = _resolveComponent("BIconSearch")!
  const _component_b_input_group_text = _resolveComponent("b-input-group-text")!
  const _component_b_input_group_prepend = _resolveComponent("b-input-group-prepend")!
  const _component_b_input = _resolveComponent("b-input")!
  const _component_b_input_group = _resolveComponent("b-input-group")!
  const _component_b_img = _resolveComponent("b-img")!
  const _component_b_button = _resolveComponent("b-button")!
  const _component_b_icon_arrow_right = _resolveComponent("b-icon-arrow-right")!
  const _component_BIconStars = _resolveComponent("BIconStars")!
  const _component_b_form_input = _resolveComponent("b-form-input")!
  const _component_b_badge = _resolveComponent("b-badge")!
  const _component_b_icon_trash = _resolveComponent("b-icon-trash")!
  const _component_b_list_group_item = _resolveComponent("b-list-group-item")!
  const _component_b_card_group = _resolveComponent("b-card-group")!
  const _component_b_icon_plus = _resolveComponent("b-icon-plus")!
  const _component_b_alert = _resolveComponent("b-alert")!
  const _component_PrivacyModal = _resolveComponent("PrivacyModal")!
  const _component_SplitUserStoriesModal = _resolveComponent("SplitUserStoriesModal")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.userStories.length > 0 || _ctx.filterActive)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          _createVNode(_component_b_input_group, null, {
            default: _withCtx(() => [
              _createVNode(_component_b_input_group_prepend, null, {
                default: _withCtx(() => [
                  _createVNode(_component_b_input_group_text, null, {
                    default: _withCtx(() => [
                      _createVNode(_component_BIconSearch, { id: "searchIcon" })
                    ], undefined, true),
                    _: 1
                  })
                ], undefined, true),
                _: 1
              }),
              _createVNode(_component_b_input, {
                id: "search",
                modelValue: _ctx.input,
                "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.input) = $event)),
                type: "text",
                placeholder: _ctx.t('page.session.before.userStories.placeholder.searchUserStories'),
                onInput: _ctx.swapPriority
              }, null, 8, ["modelValue", "placeholder", "onInput"])
            ], undefined, true),
            _: 1
          })
        ]))
      : _createCommentVNode("", true),
    _createVNode(_component_b_card_group, {
      id: "userStoryBlock",
      class: "my-2 overflow-auto"
    }, {
      default: _withCtx(() => [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.userStories, (story, index) => {
          return (_openBlock(), _createBlock(_component_b_list_group_item, {
            id: "userStoryRow",
            key: index,
            active: index === _ctx.selectedStoryIndex,
            class: "w-100 p-1 d-flex justify-content-left",
            style: _normalizeStyle(index === _ctx.selectedStoryIndex ? 'border-width: 3px;' : ''),
            onMouseover: ($event: any) => (_ctx.hover = index),
            onMouseleave: _cache[1] || (_cache[1] = ($event: any) => (_ctx.hover = null)),
            onClick: ($event: any) => (_ctx.setUserStoryAsActive(index))
          }, {
            default: _withCtx(() => [
              (_ctx.showEditButtons)
                ? (_openBlock(), _createBlock(_component_b_button, {
                    key: 0,
                    variant: "primary",
                    class: _normalizeClass(story.isActive ? 'selectedStory' : 'outlineColorStory'),
                    size: "sm",
                    onClick: ($event: any) => {
            _ctx.markUserStory(index);
            $event.target.blur();
          }
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_b_img, {
                        id: "userStoryPicture",
                        src: require('@/assets/ActiveUserStory.png')
                      }, null, 8, ["src"])
                    ], undefined, true),
                    _: 2
                  }, 1032, ["class", "onClick"]))
                : (_ctx.hostSelectedStoryIndex === index && !_ctx.showEditButtons)
                  ? (_openBlock(), _createBlock(_component_b_button, {
                      key: 1,
                      size: "sm",
                      variant: "success",
                      disabled: ""
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_b_icon_arrow_right)
                      ], undefined, true),
                      _: 1
                    }))
                  : _createCommentVNode("", true),
              (_ctx.showEditButtons && _ctx.hasApiKey)
                ? _withDirectives((_openBlock(), _createBlock(_component_b_button, {
                    key: 2,
                    id: "stars",
                    onClick: ($event: any) => {
            _ctx.selectedStoryIndex = index;
            _ctx.showPrivacyModal = true;
          }
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_BIconStars)
                    ], undefined, true),
                    _: 2
                  }, 1032, ["onClick"])), [
                    [_vShow, _ctx.userStories[index].title !== '' && _ctx.userStories[index].description !== '']
                  ])
                : _createCommentVNode("", true),
              _createVNode(_component_b_form_input, {
                id: "userStoryTitles",
                modelValue: story.title,
                "onUpdate:modelValue": ($event: any) => ((story.title) = $event),
                class: "mx-1 w-100 shadow-none",
                readonly: "",
                size: "sm",
                placeholder: _ctx.t('page.session.before.userStories.placeholder.userStoryTitle')
              }, null, 8, ["modelValue", "onUpdate:modelValue", "placeholder"]),
              _createVNode(_component_b_badge, {
                id: "badge",
                class: "p-2"
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(story.estimation == null ? "?" : story.estimation), 1)
                ], undefined, true),
                _: 2
              }, 1024),
              _withDirectives(_createVNode(_component_b_button, {
                variant: "outline-danger",
                class: "border-0",
                size: "sm",
                onClick: ($event: any) => (_ctx.deleteStory(index))
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_b_icon_trash)
                ], undefined, true),
                _: 2
              }, 1032, ["onClick"]), [
                [_vShow, _ctx.showEditButtons && _ctx.hover === index]
              ])
            ], undefined, true),
            _: 2
          }, 1032, ["active", "style", "onMouseover", "onClick"]))
        }), 128))
      ], undefined, true),
      _: 1
    }),
    (_ctx.userStories.length < 1 && _ctx.showEditButtons && !_ctx.filterActive)
      ? (_openBlock(), _createBlock(_component_b_button, {
          key: 1,
          class: "w-100 mb-1",
          variant: "primary",
          onClick: _cache[2] || (_cache[2] = ($event: any) => {
        _ctx.addUserStory();
        $event.target.blur();
      })
        }, {
          default: _withCtx(() => [
            _createVNode(_component_b_icon_plus),
            _createTextVNode(" " + _toDisplayString(_ctx.t("page.session.before.userStories.button.addFirstUserStory")), 1)
          ], undefined, true),
          _: 1
        }))
      : _createCommentVNode("", true),
    (_ctx.userStories.length < 1 && _ctx.showEditButtons && _ctx.filterActive)
      ? (_openBlock(), _createBlock(_component_b_alert, {
          key: 2,
          show: "",
          variant: "warning"
        }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.t("page.session.before.userStories.filter.noStoryFound")), 1)
          ], undefined, true),
          _: 1
        }))
      : _createCommentVNode("", true),
    (_ctx.userStories.length > 0 && _ctx.showEditButtons && !_ctx.filterActive)
      ? (_openBlock(), _createBlock(_component_b_button, {
          key: 3,
          class: "w-100 mb-1",
          variant: "primary",
          onClick: _cache[3] || (_cache[3] = ($event: any) => {
        _ctx.addUserStory();
        $event.target.blur();
      })
        }, {
          default: _withCtx(() => [
            _createVNode(_component_b_icon_plus),
            _createTextVNode(" " + _toDisplayString(_ctx.t("page.session.before.userStories.button.addUserStory")), 1)
          ], undefined, true),
          _: 1
        }))
      : _createCommentVNode("", true),
    (_ctx.showPrivacyModal)
      ? (_openBlock(), _createBlock(_component_PrivacyModal, {
          key: 4,
          "current-title": _ctx.userStories[_ctx.selectedStoryIndex].title,
          "current-text": _ctx.userStories[_ctx.selectedStoryIndex].description,
          "is-description": true,
          onResetShowModal: _cache[4] || (_cache[4] = ($event: any) => (_ctx.showPrivacyModal = false)),
          onSendGPTRequest: _ctx.submitRequest
        }, null, 8, ["current-title", "current-text", "onSendGPTRequest"]))
      : _createCommentVNode("", true),
    (_ctx.splittedUserStoriesData.length > 0 && !_ctx.showPrivacyModal)
      ? (_openBlock(), _createBlock(_component_SplitUserStoriesModal, {
          key: 5,
          "new-user-stories-list": _ctx.splittedUserStoriesData,
          "original-user-story": [_ctx.userStories[_ctx.storyToSplitIdx]],
          onResetShowModal: _cache[5] || (_cache[5] = ($event: any) => {
        _ctx.showUserStorySplitModal = false;
        _ctx.splittedUserStoriesData = [];
      }),
          onAcceptSplitting: _ctx.acceptSplitting,
          onRetry: _ctx.retry
        }, null, 8, ["new-user-stories-list", "original-user-story", "onAcceptSplitting", "onRetry"]))
      : _createCommentVNode("", true)
  ]))
}