import { toDisplayString as _toDisplayString, resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, createElementVNode as _createElementVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "text-center" }
const _hoisted_2 = { class: "numbers" }
const _hoisted_3 = { style: {"text-align":"center"} }
const _hoisted_4 = { class: "numbers" }
const _hoisted_5 = { style: {"text-align":"center"} }
const _hoisted_6 = { class: "numbers" }
const _hoisted_7 = { style: {"text-align":"center"} }
const _hoisted_8 = { class: "numbers" }
const _hoisted_9 = { style: {"text-align":"center"} }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_b_icon_bar_chart = _resolveComponent("b-icon-bar-chart")!
  const _component_b_card_title = _resolveComponent("b-card-title")!
  const _component_b_card_text = _resolveComponent("b-card-text")!
  const _component_b_card = _resolveComponent("b-card")!
  const _component_b_card_group = _resolveComponent("b-card-group")!
  const _component_b_container = _resolveComponent("b-container")!

  return (_openBlock(), _createBlock(_component_b_container, null, {
    default: _withCtx(() => [
      _createElementVNode("h1", _hoisted_1, [
        _createTextVNode(_toDisplayString(_ctx.headerText) + " ", 1),
        _createVNode(_component_b_icon_bar_chart)
      ]),
      _createVNode(_component_b_card_group, { deck: "" }, {
        default: _withCtx(() => [
          _createVNode(_component_b_card, { class: "statsCards" }, {
            default: _withCtx(() => [
              _createVNode(_component_b_card_title, { style: {"text-align":"center"} }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.t("page.landing.meeting.analytics.allCreatedSessionsTitle")), 1)
                ], undefined, true),
                _: 1
              }),
              _createVNode(_component_b_card_text, null, {
                default: _withCtx(() => [
                  _createElementVNode("h2", _hoisted_2, _toDisplayString(_ctx.tweenedOverAllSessions.toFixed(0)), 1),
                  _createElementVNode("div", _hoisted_3, _toDisplayString(_ctx.allSessions), 1),
                  _cache[0] || (_cache[0] = _createElementVNode("br", null, null, -1)),
                  _createElementVNode("h2", _hoisted_4, _toDisplayString(_ctx.tweenedOverAllAttendees.toFixed(0)), 1),
                  _createElementVNode("div", _hoisted_5, _toDisplayString(_ctx.allAttendees), 1)
                ], undefined, true),
                _: 1
              })
            ], undefined, true),
            _: 1
          }),
          _createVNode(_component_b_card, { class: "statsCards" }, {
            default: _withCtx(() => [
              _createVNode(_component_b_card_title, { style: {"text-align":"center"} }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.t("page.landing.meeting.analytics.activeTitle")), 1)
                ], undefined, true),
                _: 1
              }),
              _createVNode(_component_b_card_text, null, {
                default: _withCtx(() => [
                  _createElementVNode("h2", _hoisted_6, _toDisplayString(_ctx.tweenedCurrentSessions.toFixed(0)), 1),
                  _createElementVNode("div", _hoisted_7, _toDisplayString(_ctx.allSessions), 1),
                  _cache[1] || (_cache[1] = _createElementVNode("br", null, null, -1)),
                  _createElementVNode("h2", _hoisted_8, _toDisplayString(_ctx.tweendCurrentAttendees.toFixed(0)), 1),
                  _createElementVNode("div", _hoisted_9, _toDisplayString(_ctx.allAttendees), 1)
                ], undefined, true),
                _: 1
              })
            ], undefined, true),
            _: 1
          })
        ], undefined, true),
        _: 1
      }),
      _cache[2] || (_cache[2] = _createElementVNode("br", null, null, -1))
    ], undefined, true),
    _: 1
  }))
}