import { resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, vShow as _vShow, withCtx as _withCtx, withDirectives as _withDirectives, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, createElementVNode as _createElementVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "markdown-editor position-relative" }
const _hoisted_2 = {
  key: 1,
  id: "aiPopOver"
}
const _hoisted_3 = { id: "popoverBody" }
const _hoisted_4 = {
  key: 2,
  class: "triangle-down"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_editor = _resolveComponent("editor")!
  const _component_b_icon_stars = _resolveComponent("b-icon-stars")!
  const _component_b_button = _resolveComponent("b-button")!
  const _component_b_icon_pencil = _resolveComponent("b-icon-pencil")!
  const _component_b_icon_lightbulb = _resolveComponent("b-icon-lightbulb")!
  const _component_b_icon_input_cursor_text = _resolveComponent("b-icon-input-cursor-text")!
  const _component_PrivacyModal = _resolveComponent("PrivacyModal")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (!_ctx.disabled)
      ? (_openBlock(), _createBlock(_component_editor, {
          key: 0,
          ref: "toastuiEditor",
          "initial-value": _ctx.markdown,
          height: _ctx.height,
          options: _ctx.editorOptions,
          placeholder: _ctx.placeholder,
          "initial-edit-type": "wysiwyg",
          "preview-style": "vertical",
          class: _normalizeClass(_ctx.theme === 'light' ? 'lightMode' : 'darkMode'),
          "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('textValueChanged', { markdown: $event }))),
          onStillTyping: _cache[1] || (_cache[1] = ($event: any) => (_ctx.aiButtonVisible = false)),
          onStoppedTyping: _ctx.showAiButton
        }, null, 8, ["initial-value", "height", "options", "placeholder", "class", "onStoppedTyping"]))
      : _createCommentVNode("", true),
    _withDirectives(_createVNode(_component_b_button, {
      id: "submitAIDescription",
      onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.showPopOver = !_ctx.showPopOver))
    }, {
      default: _withCtx(() => [
        _createVNode(_component_b_icon_stars, { id: "aiStars" })
      ], undefined, true),
      _: 1
    }, 512), [
      [_vShow, _ctx.aiButtonVisible && _ctx.currentText !== '' && _ctx.hasApiKey]
    ]),
    (_ctx.showPopOver)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          _createElementVNode("div", _hoisted_3, [
            (_ctx.foundGrammar)
              ? (_openBlock(), _createBlock(_component_b_button, {
                  key: 0,
                  class: "my-1 aiDescriptionButtons",
                  onClick: _cache[3] || (_cache[3] = ($event: any) => (_ctx.aiButtonClicked('grammar')))
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_b_icon_pencil),
                    _createTextVNode(" " + _toDisplayString(_ctx.t("general.aiFeature.descriptionButtons.grammar")), 1)
                  ], undefined, true),
                  _: 1
                }))
              : _createCommentVNode("", true),
            (_ctx.foundDescription)
              ? (_openBlock(), _createBlock(_component_b_button, {
                  key: 1,
                  class: "my-1 aiDescriptionButtons",
                  onClick: _cache[4] || (_cache[4] = ($event: any) => (_ctx.aiButtonClicked('improveDescription')))
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_b_icon_lightbulb),
                    _createTextVNode(" " + _toDisplayString(_ctx.t("general.aiFeature.descriptionButtons.description")), 1)
                  ], undefined, true),
                  _: 1
                }))
              : _createCommentVNode("", true),
            _createVNode(_component_b_button, {
              class: "my-1 aiDescriptionButtons",
              onClick: _cache[5] || (_cache[5] = ($event: any) => (_ctx.aiButtonClicked('markDescription')))
            }, {
              default: _withCtx(() => [
                _createVNode(_component_b_icon_input_cursor_text),
                _createTextVNode(" " + _toDisplayString(_ctx.t("general.aiFeature.descriptionButtons.mark")), 1)
              ], undefined, true),
              _: 1
            })
          ])
        ]))
      : _createCommentVNode("", true),
    (_ctx.showPopOver)
      ? (_openBlock(), _createElementBlock("div", _hoisted_4))
      : _createCommentVNode("", true),
    (_ctx.showModal)
      ? (_openBlock(), _createBlock(_component_PrivacyModal, {
          key: 3,
          "current-text": _ctx.currentText,
          "is-description": true,
          onSendGPTRequest: _ctx.redirectSubmit,
          onResetShowModal: _cache[6] || (_cache[6] = ($event: any) => (_ctx.showModal = false))
        }, null, 8, ["current-text", "onSendGPTRequest"]))
      : _createCommentVNode("", true)
  ]))
}