import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock, createElementVNode as _createElementVNode, createCommentVNode as _createCommentVNode, vShow as _vShow, withCtx as _withCtx, withDirectives as _withDirectives, createVNode as _createVNode } from "vue"

const _hoisted_1 = { class: "user-story-descriptions" }
const _hoisted_2 = { key: 0 }
const _hoisted_3 = {
  key: 0,
  class: "text-center rounded p-3 m-2"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_markdown_editor = _resolveComponent("markdown-editor")!
  const _component_b_list_group_item = _resolveComponent("b-list-group-item")!
  const _component_b_card = _resolveComponent("b-card")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.userStories, (story, idx) => {
      return _withDirectives((_openBlock(), _createBlock(_component_b_list_group_item, {
        key: story.id,
        class: "border-0 description-box",
        variant: "outline-secondary"
      }, {
        default: _withCtx(() => [
          _createElementVNode("div", null, [
            (_openBlock(), _createBlock(_component_markdown_editor, {
              id: "textarea-auto-height",
              key: _ctx.updateComponent,
              modelValue: _ctx.userStories[idx].description,
              "onUpdate:modelValue": ($event: any) => ((_ctx.userStories[idx].description) = $event),
              class: "my-2",
              disabled: !_ctx.editDescription,
              placeholder: _ctx.t('page.session.before.userStories.placeholder.userStoryDescription'),
              "accepted-stories": _ctx.acceptedStories,
              "current-story-i-d": _ctx.userStories[idx].id,
              "has-api-key": _ctx.hasApiKey,
              onTextValueChanged: (event) => _ctx.valueChanged(idx, event),
              onSendGPTDescriptionRequest: _ctx.sendGPTDescriptionRequest
            }, null, 8, ["modelValue", "onUpdate:modelValue", "disabled", "placeholder", "accepted-stories", "current-story-i-d", "has-api-key", "onTextValueChanged", "onSendGPTDescriptionRequest"]))
          ]),
          (!_ctx.editDescription)
            ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
                (_openBlock(), _createBlock(_component_markdown_editor, {
                  id: "textarea-auto-height",
                  key: _ctx.updateComponent,
                  modelValue: _ctx.userStories[idx].description,
                  "onUpdate:modelValue": ($event: any) => ((_ctx.userStories[idx].description) = $event),
                  class: "my-2 noneClickable",
                  placeholder: _ctx.t('page.session.before.userStories.placeholder.userStoryDescription'),
                  onTextValueChanged: (event) => _ctx.valueChanged(idx, event),
                  onSendGPTDescriptionRequest: _ctx.sendGPTDescriptionRequest
                }, null, 8, ["modelValue", "onUpdate:modelValue", "placeholder", "onTextValueChanged", "onSendGPTDescriptionRequest"]))
              ]))
            : _createCommentVNode("", true)
        ], undefined, true),
        _: 2
      }, 1024)), [
        [_vShow, idx === _ctx.index]
      ])
    }), 128)),
    (_ctx.userStories.length <= _ctx.index && _ctx.userStories.length)
      ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
          _createVNode(_component_b_card, {
            class: "border-0",
            title: _ctx.t('page.session.before.userStories.text')
          }, null, 8, ["title"])
        ]))
      : _createCommentVNode("", true)
  ]))
}