import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock, createElementVNode as _createElementVNode, createVNode as _createVNode } from "vue"

const _hoisted_1 = { style: {"list-style-type":"none","padding":"0"} }
const _hoisted_2 = { style: {"list-style-type":"disc"} }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_b_dropdown_item = _resolveComponent("b-dropdown-item")!
  const _component_b_dropdown_divider = _resolveComponent("b-dropdown-divider")!
  const _component_b_nav_item_dropdown = _resolveComponent("b-nav-item-dropdown")!

  return (_openBlock(), _createElementBlock("ul", _hoisted_1, [
    _createVNode(_component_b_nav_item_dropdown, {
      text: _ctx.locales[_ctx.currentLocale],
      right: ""
    }, {
      default: _withCtx(() => [
        _createElementVNode("ul", _hoisted_2, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.locales, (locale, key) => {
            return (_openBlock(), _createBlock(_component_b_dropdown_item, {
              key: key,
              active: _ctx.currentLocale === key,
              class: "text-light",
              onClick: ($event: any) => (_ctx.setLocale(key))
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(locale), 1)
              ], undefined, true),
              _: 2
            }, 1032, ["active", "onClick"]))
          }), 128))
        ]),
        _createVNode(_component_b_dropdown_divider),
        _createVNode(_component_b_dropdown_item, { href: "https://crowdin.com/project/diveni" }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.t("general.licenses.translations")), 1)
          ], undefined, true),
          _: 1
        })
      ], undefined, true),
      _: 1
    }, 8, ["text"])
  ]))
}