import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_b_modal = _resolveComponent("b-modal")!

  return (_openBlock(), _createBlock(_component_b_modal, {
    modelValue: _ctx.checkIfNotInstalled,
    "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.checkIfNotInstalled) = $event)),
    class: "test",
    centered: "",
    title: "Download Diveni",
    onOk: _cache[1] || (_cache[1] = ($event: any) => (_ctx.install())),
    onHide: _cache[2] || (_cache[2] = ($event: any) => (_ctx.closeModal()))
  }, {
    default: _withCtx(() => [
      _createTextVNode(_toDisplayString(_ctx.t("page.landing.install")), 1)
    ], undefined, true),
    _: 1
  }, 8, ["modelValue"]))
}