import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, resolveDirective as _resolveDirective, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, withDirectives as _withDirectives, createElementVNode as _createElementVNode, createVNode as _createVNode, withModifiers as _withModifiers, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_b_button = _resolveComponent("b-button")!
  const _component_b_form_input = _resolveComponent("b-form-input")!
  const _component_b_form_group = _resolveComponent("b-form-group")!
  const _component_b_modal = _resolveComponent("b-modal")!
  const _directive_b_modal = _resolveDirective("b-modal")!

  return (_openBlock(), _createElementBlock("div", null, [
    _withDirectives((_openBlock(), _createBlock(_component_b_button, {
      disabled: _ctx.disabled,
      variant: "primary",
      onClick: _cache[0] || (_cache[0] = ($event: any) => {
        _ctx.openSignInWithJiraTab();
        _ctx.openModal();
        $event.target.blur();
      })
    }, {
      default: _withCtx(() => [
        _createTextVNode(_toDisplayString(_ctx.t(
          "session.prepare.step.selection.mode.description.withIssueTracker.buttons.signInWithJiraServer.label"
        )), 1)
      ], undefined, true),
      _: 1
    }, 8, ["disabled"])), [
      [
        _directive_b_modal,
        void 0,
        void 0,
        { "modal-verification-code": true }
      ]
    ]),
    (_ctx.showVerificationModal)
      ? (_openBlock(), _createBlock(_component_b_modal, {
          key: 0,
          id: "modal-verification-code",
          ref: "modal",
          title: "Verification code",
          onShow: _ctx.resetModal,
          onHidden: _ctx.resetModal,
          onOk: _ctx.handleOk
        }, {
          default: _withCtx(() => [
            _createElementVNode("p", null, _toDisplayString(_ctx.t("session.prepare.step.selection.mode.description.withIssueTracker.dialog.description")), 1),
            _createElementVNode("form", {
              ref: "form",
              onSubmit: _cache[2] || (_cache[2] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.handleSubmit && _ctx.handleSubmit(...args)), ["stop","prevent"]))
            }, [
              _createVNode(_component_b_form_group, {
                label: "Verification code",
                "label-for": "input-verification-code",
                "invalid-feedback": "Verification code is required",
                state: _ctx.verificationCodeState
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_b_form_input, {
                    id: "input-verification-code",
                    modelValue: _ctx.verificationCode,
                    "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.verificationCode) = $event)),
                    required: "",
                    placeholder: 
              _ctx.t(
                'session.prepare.step.selection.mode.description.withIssueTracker.inputs.verificationCode.placeholder'
              )
            ,
                    state: _ctx.verificationCodeState
                  }, null, 8, ["modelValue", "placeholder", "state"])
                ], undefined, true),
                _: 1
              }, 8, ["state"])
            ], 544)
          ], undefined, true),
          _: 1
        }, 8, ["onShow", "onHidden", "onOk"]))
      : _createCommentVNode("", true)
  ]))
}