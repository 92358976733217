import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode } from "vue"

const _hoisted_1 = { ref: "projectSelectionRef" }
const _hoisted_2 = {
  key: 0,
  class: "vue-autocomplete-input-tag-items"
}
const _hoisted_3 = ["onClick"]
const _hoisted_4 = { class: "mt-3" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_BIconSearch = _resolveComponent("BIconSearch")!
  const _component_b_input_group_text = _resolveComponent("b-input-group-text")!
  const _component_b_input_group_prepend = _resolveComponent("b-input-group-prepend")!
  const _component_b_input = _resolveComponent("b-input")!
  const _component_b_input_group = _resolveComponent("b-input-group")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_b_input_group, null, {
      default: _withCtx(() => [
        _createVNode(_component_b_input_group_prepend, null, {
          default: _withCtx(() => [
            _createVNode(_component_b_input_group_text, null, {
              default: _withCtx(() => [
                _createVNode(_component_BIconSearch, { id: "searchIcon" })
              ], undefined, true),
              _: 1
            })
          ], undefined, true),
          _: 1
        }),
        _createVNode(_component_b_input, {
          id: "search",
          modelValue: _ctx.input,
          "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.input) = $event)),
          type: "text",
          placeholder: 
          _ctx.t(
            'session.prepare.step.selection.mode.description.withIssueTracker.placeholder.searchProjects'
          )
        ,
          onInput: _cache[1] || (_cache[1] = ($event: any) => (_ctx.filterProjects())),
          onFocus: _cache[2] || (_cache[2] = ($event: any) => {
          _ctx.showResult = true;
          _ctx.filterProjects();
        })
        }, null, 8, ["modelValue", "placeholder"])
      ], undefined, true),
      _: 1
    }),
    (_ctx.showResult)
      ? (_openBlock(), _createElementBlock("ul", _hoisted_2, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.projectNamesList, (name) => {
            return (_openBlock(), _createElementBlock("li", {
              key: name,
              class: "vue-autocomplete-input-tag-item",
              onClick: ($event: any) => (_ctx.selectProject(name))
            }, _toDisplayString(name), 9, _hoisted_3))
          }), 128))
        ]))
      : _createCommentVNode("", true),
    _createElementVNode("div", _hoisted_4, [
      _createTextVNode(_toDisplayString(_ctx.t("session.prepare.step.selection.mode.description.withIssueTracker.selectedProject")) + " ", 1),
      _createElementVNode("strong", null, _toDisplayString(_ctx.selected || "-"), 1)
    ])
  ], 512))
}