import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { id: "landing-page" }
const _hoisted_2 = { class: "parent pb-md-5 px-5" }
const _hoisted_3 = { class: "text-center" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_b_jumbotron = _resolveComponent("b-jumbotron")!
  const _component_b_container = _resolveComponent("b-container")!
  const _component_landing_page_card = _resolveComponent("landing-page-card")!
  const _component_b_card_group = _resolveComponent("b-card-group")!
  const _component_AnalyticsDataComponent = _resolveComponent("AnalyticsDataComponent")!
  const _component_b_icon_gear_wide = _resolveComponent("b-icon-gear-wide")!
  const _component_b_img_lazy = _resolveComponent("b-img-lazy")!
  const _component_b_card_text = _resolveComponent("b-card-text")!
  const _component_b_card = _resolveComponent("b-card")!
  const _component_b_card_sub_title = _resolveComponent("b-card-sub-title")!
  const _component_CarouselComponent = _resolveComponent("CarouselComponent")!
  const _component_DownloadPWAModal = _resolveComponent("DownloadPWAModal")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_b_container, {
      fluid: "",
      class: "teaser"
    }, {
      default: _withCtx(() => [
        _createVNode(_component_b_container, null, {
          default: _withCtx(() => [
            _createVNode(_component_b_jumbotron, {
              header: "DIVENI",
              lead: "Instant free and easy remote Planning Poker"
            })
          ], undefined, true),
          _: 1
        })
      ], undefined, true),
      _: 1
    }),
    _createVNode(_component_b_container, { class: "my-5" }, {
      default: _withCtx(() => [
        _createVNode(_component_b_card_group, {
          deck: "",
          class: "justify-content-center"
        }, {
          default: _withCtx(() => [
            _createVNode(_component_landing_page_card, {
              title: _ctx.t('page.landing.meeting.new.title'),
              description: _ctx.t('page.landing.meeting.new.description'),
              "button-text": _ctx.t('page.landing.meeting.new.buttons.start.label'),
              "on-click": _ctx.goToPrepareSessionPage,
              "button-variant": "primary",
              class: "newSessionCard"
            }, null, 8, ["title", "description", "button-text", "on-click"]),
            _createVNode(_component_landing_page_card, {
              title: _ctx.t('page.landing.meeting.join.title'),
              description: _ctx.t('page.landing.meeting.join.description'),
              "button-text": _ctx.t('page.landing.meeting.join.buttons.start.label'),
              "on-click": _ctx.goToJoinPage,
              "button-variant": "secondary",
              class: "joinSessionCard"
            }, null, 8, ["title", "description", "button-text", "on-click"]),
            (_ctx.sessionWrapper.session)
              ? (_openBlock(), _createBlock(_component_landing_page_card, {
                  key: 0,
                  title: _ctx.t('page.landing.meeting.reconnect.title'),
                  description: _ctx.t('page.landing.meeting.reconnect.description'),
                  "button-text": _ctx.t('page.landing.meeting.reconnect.buttons.start.label'),
                  "on-click": _ctx.goToSessionPage,
                  class: "reconnectSessionCard"
                }, null, 8, ["title", "description", "button-text", "on-click"]))
              : _createCommentVNode("", true)
          ], undefined, true),
          _: 1
        })
      ], undefined, true),
      _: 1
    }),
    _createVNode(_component_AnalyticsDataComponent, { ref: "dataComponent" }, null, 512),
    _createVNode(_component_b_container, null, {
      default: _withCtx(() => [
        _createElementVNode("div", _hoisted_2, [
          _cache[11] || (_cache[11] = _createElementVNode("div", { class: "py-5" }, null, -1)),
          _createElementVNode("div", null, [
            _createElementVNode("h1", _hoisted_3, [
              _cache[0] || (_cache[0] = _createTextVNode("How DIVENI works ")),
              _createVNode(_component_b_icon_gear_wide)
            ]),
            _createVNode(_component_b_card_group, {
              deck: "",
              class: "py-5"
            }, {
              default: _withCtx(() => [
                _createVNode(_component_b_card, { class: "pictureCard" }, {
                  default: _withCtx(() => [
                    _createVNode(_component_b_card_text, null, {
                      default: _withCtx(() => [
                        _createVNode(_component_b_img_lazy, {
                          src: require(`@/assets/SetUpSession.png`),
                          width: "300",
                          height: "265",
                          alt: "Set Up Session Picture"
                        }, null, 8, ["src"])
                      ], undefined, true),
                      _: 1
                    })
                  ], undefined, true),
                  _: 1
                }),
                _createVNode(_component_b_card, {
                  class: "aboutDiveni",
                  title: "Set up Session"
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_b_card_text, null, {
                      default: _withCtx(() => _cache[1] || (_cache[1] = [
                        _createTextVNode(" Create a planning session and select your prefered voting set. ")
                      ]), undefined, true),
                      _: 1
                    }),
                    _createVNode(_component_b_card_text, null, {
                      default: _withCtx(() => _cache[2] || (_cache[2] = [
                        _createTextVNode(" You could import your user stories or connect JIRA to syncronize story points. ")
                      ]), undefined, true),
                      _: 1
                    }),
                    _createVNode(_component_b_card_sub_title, null, {
                      default: _withCtx(() => _cache[3] || (_cache[3] = [
                        _createElementVNode("a", {
                          href: "https://sybit-education.github.io/Diveni/guide",
                          target: "_blank"
                        }, " Connecting to Issue-Tracker", -1)
                      ]), undefined, true),
                      _: 1
                    }),
                    _createVNode(_component_b_card_text, null, {
                      default: _withCtx(() => _cache[4] || (_cache[4] = [
                        _createTextVNode(" DIVENI could connect to issue trackers like Azure DevOps, JIRA Server and Cloud to show user stories and update the voted results of your planning poker. ")
                      ]), undefined, true),
                      _: 1
                    })
                  ], undefined, true),
                  _: 1
                })
              ], undefined, true),
              _: 1
            }),
            _createVNode(_component_b_card_group, null, {
              default: _withCtx(() => [
                _createVNode(_component_b_card, {
                  class: "aboutDiveni",
                  title: "Invite your Team"
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_b_card_text, null, {
                      default: _withCtx(() => _cache[5] || (_cache[5] = [
                        _createTextVNode(" Invite your team using QR-Code, invite link or code. ")
                      ]), undefined, true),
                      _: 1
                    }),
                    _createVNode(_component_b_card_text, null, {
                      default: _withCtx(() => _cache[6] || (_cache[6] = [
                        _createTextVNode(" Every user will be randomly assinged to a animal and is ready to estimate. ")
                      ]), undefined, true),
                      _: 1
                    }),
                    _createVNode(_component_b_card_text, null, {
                      default: _withCtx(() => _cache[7] || (_cache[7] = [
                        _createTextVNode(" If everybody is in the session, you could start estimation. Having defined a time limit this will be used as limit for voting time. ")
                      ]), undefined, true),
                      _: 1
                    })
                  ], undefined, true),
                  _: 1
                }),
                _createVNode(_component_b_card, { class: "pictureCard" }, {
                  default: _withCtx(() => [
                    _createVNode(_component_b_card_text, null, {
                      default: _withCtx(() => [
                        _createVNode(_component_b_img_lazy, {
                          src: require(`@/assets/InviteYourTeam.png`),
                          width: "300",
                          height: "265",
                          alt: "Invite Your Team Picture"
                        }, null, 8, ["src"])
                      ], undefined, true),
                      _: 1
                    })
                  ], undefined, true),
                  _: 1
                })
              ], undefined, true),
              _: 1
            }),
            _createVNode(_component_b_card_group, null, {
              default: _withCtx(() => [
                _createVNode(_component_b_card, { class: "pictureCard" }, {
                  default: _withCtx(() => [
                    _createVNode(_component_b_card_text, null, {
                      default: _withCtx(() => [
                        _createVNode(_component_b_img_lazy, {
                          src: require(`@/assets/EstimateUserStories.png`),
                          width: "300",
                          height: "265",
                          alt: "Estimate User Stories Picture"
                        }, null, 8, ["src"])
                      ], undefined, true),
                      _: 1
                    })
                  ], undefined, true),
                  _: 1
                }),
                _createVNode(_component_b_card, {
                  class: "aboutDiveni",
                  title: "Estimate User Stories"
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_b_card_text, null, {
                      default: _withCtx(() => _cache[8] || (_cache[8] = [
                        _createTextVNode(" Every member of team could use your defined set to vote the selected user story. ")
                      ]), undefined, true),
                      _: 1
                    }),
                    _createVNode(_component_b_card_text, null, {
                      default: _withCtx(() => _cache[9] || (_cache[9] = [
                        _createTextVNode(" If everybody has voted, DIVENI shows results and randomly selects two voters having voted minimum / maximum to discuss their estimation. ")
                      ]), undefined, true),
                      _: 1
                    }),
                    _createVNode(_component_b_card_text, null, {
                      default: _withCtx(() => _cache[10] || (_cache[10] = [
                        _createTextVNode(" After discussion you could repeat estimation. ")
                      ]), undefined, true),
                      _: 1
                    })
                  ], undefined, true),
                  _: 1
                })
              ], undefined, true),
              _: 1
            })
          ])
        ]),
        _cache[12] || (_cache[12] = _createElementVNode("h1", { class: "mt-5 text-center pt-md-5" }, "About DIVENI", -1)),
        _cache[13] || (_cache[13] = _createElementVNode("p", { class: "text-center" }, " DIVENI was initially developed by students of HTWG Constance and is open source now. ", -1)),
        _cache[14] || (_cache[14] = _createElementVNode("p", { class: "text-center" }, [
          _createTextVNode(" More information could be found in the "),
          _createElementVNode("a", { href: "https://docs.diveni.io/" }, "documentation"),
          _createTextVNode(". ")
        ], -1))
      ], undefined, true),
      _: 1
    }),
    _createVNode(_component_b_container, { class: "py-3" }, {
      default: _withCtx(() => _cache[15] || (_cache[15] = [
        _createElementVNode("h1", { class: "mt-5 text-center" }, "Connectors", -1)
      ]), undefined, true),
      _: 1
    }),
    _createVNode(_component_CarouselComponent, { class: "py-5" }),
    _createVNode(_component_DownloadPWAModal)
  ]))
}