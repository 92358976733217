import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createTextVNode as _createTextVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = { key: 0 }
const _hoisted_2 = { key: 1 }
const _hoisted_3 = {
  key: 0,
  class: "d-inline"
}
const _hoisted_4 = { key: 2 }
const _hoisted_5 = { key: 3 }
const _hoisted_6 = { key: 0 }
const _hoisted_7 = { key: 1 }
const _hoisted_8 = { key: 6 }
const _hoisted_9 = { key: 0 }
const _hoisted_10 = {
  key: 1,
  class: "newVotes m-1"
}
const _hoisted_11 = {
  key: 0,
  class: "refreshUserstories"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_b_col = _resolveComponent("b-col")!
  const _component_b_icon_eye_slash_fill = _resolveComponent("b-icon-eye-slash-fill")!
  const _component_b_button = _resolveComponent("b-button")!
  const _component_b_icon_eye_fill = _resolveComponent("b-icon-eye-fill")!
  const _component_copy_session_id_popup = _resolveComponent("copy-session-id-popup")!
  const _component_session_close_button = _resolveComponent("session-close-button")!
  const _component_b_row = _resolveComponent("b-row")!
  const _component_kick_user_wrapper = _resolveComponent("kick-user-wrapper")!
  const _component_session_start_button = _resolveComponent("session-start-button")!
  const _component_BIconArrowClockwise = _resolveComponent("BIconArrowClockwise")!
  const _component_BIconBarChartFill = _resolveComponent("BIconBarChartFill")!
  const _component_estimate_timer = _resolveComponent("estimate-timer")!
  const _component_session_admin_card = _resolveComponent("session-admin-card")!
  const _component_user_story_sum_component = _resolveComponent("user-story-sum-component")!
  const _component_user_stories = _resolveComponent("user-stories")!
  const _component_b_spinner = _resolveComponent("b-spinner")!
  const _component_GptModal = _resolveComponent("GptModal")!
  const _component_user_story_title = _resolveComponent("user-story-title")!
  const _component_user_story_descriptions = _resolveComponent("user-story-descriptions")!
  const _component_notify_host_component = _resolveComponent("notify-host-component")!
  const _component_b_container = _resolveComponent("b-container")!

  return (_openBlock(), _createBlock(_component_b_container, { id: "session-page" }, {
    default: _withCtx(() => [
      _createVNode(_component_b_row, { class: "headers" }, {
        default: _withCtx(() => [
          _createVNode(_component_b_col, {
            cols: "auto",
            sm: "8"
          }, {
            default: _withCtx(() => [
              _createElementVNode("h1", null, _toDisplayString(_ctx.planningStart
              ? _ctx.t("page.session.during.estimation.title")
              : _ctx.t("page.session.before.title")), 1)
            ], undefined, true),
            _: 1
          }),
          _createVNode(_component_b_col, null, {
            default: _withCtx(() => [
              (!_ctx.autoReveal && !_ctx.planningStart)
                ? (_openBlock(), _createBlock(_component_b_button, {
                    key: 0,
                    class: "mr-3 autoRevealButtons optionButton",
                    variant: "outline-dark",
                    onClick: _cache[0] || (_cache[0] = ($event: any) => {
            _ctx.autoReveal = true;
            $event.target.blur();
          })
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_b_icon_eye_slash_fill, { class: "bIcons" }),
                      _createTextVNode(" " + _toDisplayString(_ctx.t("page.session.during.estimation.buttons.autoRevealOff")), 1)
                    ], undefined, true),
                    _: 1
                  }))
                : _createCommentVNode("", true),
              (_ctx.autoReveal && !_ctx.planningStart)
                ? (_openBlock(), _createBlock(_component_b_button, {
                    key: 1,
                    class: "mr-3 autoRevealButtons optionButton",
                    variant: "outline-dark",
                    onClick: _cache[1] || (_cache[1] = ($event: any) => {
            _ctx.autoReveal = false;
            $event.target.blur();
          })
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_b_icon_eye_fill, { class: "bIcons" }),
                      _createTextVNode(" " + _toDisplayString(_ctx.t("page.session.during.estimation.buttons.autoRevealOn")), 1)
                    ], undefined, true),
                    _: 1
                  }))
                : _createCommentVNode("", true)
            ], undefined, true),
            _: 1
          }),
          _createVNode(_component_b_col, {
            cols: "auto",
            class: "mr-auto"
          }, {
            default: _withCtx(() => [
              (_ctx.planningStart)
                ? (_openBlock(), _createBlock(_component_copy_session_id_popup, {
                    key: 0,
                    class: "float-end",
                    "session-id": _ctx.sessionID
                  }, null, 8, ["session-id"]))
                : _createCommentVNode("", true)
            ], undefined, true),
            _: 1
          }),
          _createVNode(_component_b_col, { cols: "auto" }, {
            default: _withCtx(() => [
              _createVNode(_component_session_close_button, {
                "is-planning-start": _ctx.planningStart,
                "user-story-mode": _ctx.userStoryMode
              }, null, 8, ["is-planning-start", "user-story-mode"])
            ], undefined, true),
            _: 1
          })
        ], undefined, true),
        _: 1
      }),
      (!_ctx.planningStart)
        ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
            _createVNode(_component_copy_session_id_popup, {
              "text-before-session-i-d": _ctx.t('page.session.before.text.beforeID'),
              "session-id": _ctx.sessionID,
              "text-after-session-i-d": _ctx.t('page.session.before.text.afterID'),
              class: "copy-popup"
            }, null, 8, ["text-before-session-i-d", "session-id", "text-after-session-i-d"]),
            _createVNode(_component_b_row, {
              class: _normalizeClass(["d-flex justify-content-center overflow-auto kick-user", _ctx.isMobile ? 'avatar-maxHeight' : ''])
            }, {
              default: _withCtx(() => [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.members, (member) => {
                  return (_openBlock(), _createBlock(_component_kick_user_wrapper, {
                    key: member.memberID,
                    class: _normalizeClass(_ctx.isMobile ? 'm-4' : 'spaceBetweenAvatar'),
                    child: "RoundedAvatar",
                    member: member
                  }, null, 8, ["class", "member"]))
                }), 128))
              ], undefined, true),
              _: 1
            }, 8, ["class"]),
            _createVNode(_component_b_row, null, {
              default: _withCtx(() => [
                _createVNode(_component_b_col, { class: "text-center" }, {
                  default: _withCtx(() => [
                    _createVNode(_component_session_start_button, {
                      "host-voting": _ctx.hostVoting,
                      "auto-reveal": _ctx.autoReveal,
                      onClicked: _ctx.onPlanningStarted
                    }, null, 8, ["host-voting", "auto-reveal", "onClicked"])
                  ], undefined, true),
                  _: 1
                })
              ], undefined, true),
              _: 1
            })
          ]))
        : (_openBlock(), _createElementBlock("div", _hoisted_2, [
            _createVNode(_component_b_row, { class: "d-flex justify-content-start pb-3" }, {
              default: _withCtx(() => [
                _createVNode(_component_b_col, {
                  cols: "auto",
                  class: "mr-auto optionButtonCol"
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_b_button, {
                      class: "mr-3 optionButton",
                      variant: "outline-dark",
                      onClick: _cache[2] || (_cache[2] = ($event: any) => {
              _ctx.sendRestartMessage();
              $event.target.blur();
            })
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_BIconArrowClockwise, { class: "bIcons" }),
                        _createTextVNode(" " + _toDisplayString(_ctx.t("page.session.during.estimation.buttons.new")), 1)
                      ], undefined, true),
                      _: 1
                    }),
                    _createVNode(_component_b_button, {
                      class: "mr-3 optionButton",
                      variant: "outline-dark",
                      onClick: _cache[3] || (_cache[3] = ($event: any) => {
              _ctx.sendVotingFinishedMessage();
              $event.target.blur();
            })
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_BIconBarChartFill, { class: "bIcons" }),
                        _createTextVNode(" " + _toDisplayString(_ctx.t("page.session.during.estimation.buttons.result")), 1)
                      ], undefined, true),
                      _: 1
                    }),
                    (!_ctx.autoReveal)
                      ? (_openBlock(), _createBlock(_component_b_button, {
                          key: 0,
                          class: "mr-3 optionButton",
                          variant: "outline-dark",
                          disabled: _ctx.planningStart && !_ctx.estimateFinished,
                          onClick: _cache[4] || (_cache[4] = ($event: any) => {
              _ctx.autoReveal = true;
              $event.target.blur();
            })
                        }, {
                          default: _withCtx(() => [
                            _createVNode(_component_b_icon_eye_slash_fill, { class: "bIcons" }),
                            _createTextVNode(" " + _toDisplayString(_ctx.t("page.session.during.estimation.buttons.autoRevealOff")), 1)
                          ], undefined, true),
                          _: 1
                        }, 8, ["disabled"]))
                      : _createCommentVNode("", true),
                    (_ctx.autoReveal)
                      ? (_openBlock(), _createBlock(_component_b_button, {
                          key: 1,
                          class: "mr-3 optionButton",
                          variant: "outline-dark",
                          disabled: _ctx.planningStart && !_ctx.estimateFinished,
                          onClick: _cache[5] || (_cache[5] = ($event: any) => {
              _ctx.autoReveal = false;
              $event.target.blur();
            })
                        }, {
                          default: _withCtx(() => [
                            _createVNode(_component_b_icon_eye_fill, { class: "bIcons" }),
                            _createTextVNode(" " + _toDisplayString(_ctx.t("page.session.during.estimation.buttons.autoRevealOn")), 1)
                          ], undefined, true),
                          _: 1
                        }, 8, ["disabled"]))
                      : _createCommentVNode("", true)
                  ], undefined, true),
                  _: 1
                }),
                _createVNode(_component_b_col, { cols: "auto" }, {
                  default: _withCtx(() => [
                    _createVNode(_component_estimate_timer, {
                      "start-timestamp": _ctx.timerTimestamp,
                      "pause-timer": _ctx.estimateFinished,
                      duration: _ctx.timerCountdownNumber,
                      "voting-started": _ctx.planningStart,
                      onTimerFinished: _ctx.sendVotingFinishedMessage
                    }, null, 8, ["start-timestamp", "pause-timer", "duration", "voting-started", "onTimerFinished"])
                  ], undefined, true),
                  _: 1
                })
              ], undefined, true),
              _: 1
            }),
            (_ctx.membersPending.length > 0 && !_ctx.estimateFinished)
              ? (_openBlock(), _createElementBlock("h4", _hoisted_3, _toDisplayString(_ctx.t("page.session.during.estimation.message.waitingFor")) + " " + _toDisplayString(_ctx.membersPending.length) + " / " + _toDisplayString(_ctx.membersPending.length + _ctx.membersEstimated.length), 1))
              : _createCommentVNode("", true),
            (!_ctx.estimateFinished)
              ? (_openBlock(), _createBlock(_component_b_row, {
                  key: 1,
                  class: _normalizeClass(["d-flex justify-content-center overflow-auto", _ctx.isMobile ? 'avatar-maxHeight' : ''])
                }, {
                  default: _withCtx(() => [
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.membersPending, (member) => {
                      return (_openBlock(), _createBlock(_component_kick_user_wrapper, {
                        key: member.memberID,
                        class: _normalizeClass(_ctx.isMobile ? 'm-4' : 'spaceBetweenAvatar'),
                        child: "RoundedAvatar",
                        member: member
                      }, null, 8, ["class", "member"]))
                    }), 128))
                  ], undefined, true),
                  _: 1
                }, 8, ["class"]))
              : _createCommentVNode("", true),
            _cache[9] || (_cache[9] = _createElementVNode("hr", { class: "my-5 breakingLine" }, null, -1)),
            (!_ctx.hostVoting)
              ? (_openBlock(), _createElementBlock("h4", _hoisted_4, _toDisplayString(_ctx.t("page.session.during.estimation.message.finished")) + " " + _toDisplayString(_ctx.membersEstimated.length) + " / " + _toDisplayString(_ctx.members.length), 1))
              : (_openBlock(), _createElementBlock("h4", _hoisted_5, [
                  (_ctx.hostEstimation == '')
                    ? (_openBlock(), _createElementBlock("div", _hoisted_6, _toDisplayString(_ctx.t("page.session.during.estimation.message.finished")) + " " + _toDisplayString(_ctx.membersEstimated.length) + " / " + _toDisplayString(_ctx.members.length + 1), 1))
                    : (_openBlock(), _createElementBlock("div", _hoisted_7, _toDisplayString(_ctx.t("page.session.during.estimation.message.finished")) + " " + _toDisplayString(_ctx.membersEstimated.length + 1) + " / " + _toDisplayString(_ctx.members.length + 1), 1))
                ])),
            (_ctx.highlightedMembers.includes(_ctx.adminID))
              ? (_openBlock(), _createBlock(_component_b_row, {
                  key: 4,
                  class: "my-1 d-flex justify-content-center flex-wrap overflow-auto kick-user",
                  style: {"max-height":"500px"}
                }, {
                  default: _withCtx(() => [
                    ((_ctx.estimateFinished && _ctx.hostVoting) || _ctx.hostEstimation !== '')
                      ? (_openBlock(), _createBlock(_component_session_admin_card, {
                          key: 0,
                          "current-estimation": _ctx.hostEstimation,
                          "estimate-finished": _ctx.estimateFinished,
                          highlight: _ctx.highlightedMembers.includes(_ctx.adminID) || _ctx.highlightedMembers.length === 0
                        }, null, 8, ["current-estimation", "estimate-finished", "highlight"]))
                      : _createCommentVNode("", true),
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.estimateFinished ? _ctx.members : _ctx.membersEstimated, (member) => {
                      return (_openBlock(), _createBlock(_component_kick_user_wrapper, {
                        key: member.memberID,
                        child: "SessionMemberCard",
                        member: member,
                        props: {
            estimateFinished: _ctx.estimateFinished,
            highlight:
              _ctx.highlightedMembers.includes(member.memberID) || _ctx.highlightedMembers.length === 0,
          }
                      }, null, 8, ["member", "props"]))
                    }), 128))
                  ], undefined, true),
                  _: 1
                }))
              : (_openBlock(), _createBlock(_component_b_row, {
                  key: 5,
                  class: "my-1 d-flex justify-content-center flex-wrap overflow-auto",
                  style: {"max-height":"500px"}
                }, {
                  default: _withCtx(() => [
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.estimateFinished ? _ctx.members : _ctx.membersEstimated, (member) => {
                      return (_openBlock(), _createBlock(_component_kick_user_wrapper, {
                        key: member.memberID,
                        child: "SessionMemberCard",
                        member: member,
                        props: {
            estimateFinished: _ctx.estimateFinished,
            highlight:
              _ctx.highlightedMembers.includes(member.memberID) || _ctx.highlightedMembers.length === 0,
          }
                      }, null, 8, ["member", "props"]))
                    }), 128)),
                    ((_ctx.estimateFinished && _ctx.hostVoting) || _ctx.hostEstimation !== '')
                      ? (_openBlock(), _createBlock(_component_session_admin_card, {
                          key: 0,
                          "current-estimation": _ctx.hostEstimation,
                          "estimate-finished": _ctx.estimateFinished,
                          highlight: _ctx.highlightedMembers.includes(_ctx.adminID) || _ctx.highlightedMembers.length === 0
                        }, null, 8, ["current-estimation", "estimate-finished", "highlight"]))
                      : _createCommentVNode("", true)
                  ], undefined, true),
                  _: 1
                })),
            (_ctx.hostVoting && !_ctx.estimateFinished)
              ? (_openBlock(), _createElementBlock("div", _hoisted_8, [
                  (!_ctx.estimateFinished)
                    ? (_openBlock(), _createElementBlock("div", _hoisted_9, _cache[8] || (_cache[8] = [
                        _createElementVNode("hr", { class: "breakingLine" }, null, -1),
                        _createElementVNode("h4", { class: "d-inline" }, "Your Estimation", -1)
                      ])))
                    : _createCommentVNode("", true),
                  (!_ctx.estimateFinished)
                    ? (_openBlock(), _createElementBlock("div", _hoisted_10, [
                        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.voteSet, (item) => {
                          return (_openBlock(), _createBlock(_component_b_button, {
                            key: item,
                            variant: "primary",
                            class: "activePills m-1",
                            pill: "",
                            style: {"width":"60px"},
                            onClick: ($event: any) => (_ctx.vote(item))
                          }, {
                            default: _withCtx(() => [
                              _createTextVNode(_toDisplayString(item), 1)
                            ], undefined, true),
                            _: 2
                          }, 1032, ["onClick"]))
                        }), 128))
                      ]))
                    : _createCommentVNode("", true)
                ]))
              : _createCommentVNode("", true)
          ])),
      (_ctx.userStoryMode !== 'NO_US')
        ? (_openBlock(), _createBlock(_component_b_row, { key: 2 }, {
            default: _withCtx(() => [
              _createVNode(_component_b_col, null, {
                default: _withCtx(() => [
                  _createVNode(_component_user_story_sum_component)
                ], undefined, true),
                _: 1
              })
            ], undefined, true),
            _: 1
          }))
        : _createCommentVNode("", true),
      (_ctx.userStoryMode !== 'NO_US')
        ? (_openBlock(), _createBlock(_component_b_row, {
            key: 3,
            class: "d-flex flex-wrap"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_b_col, {
                cols: "12",
                md: "5",
                class: "userStories"
              }, {
                default: _withCtx(() => [
                  (_openBlock(), _createBlock(_component_user_stories, {
                    key: _ctx.splitted_user_stories,
                    "card-set": _ctx.voteSet,
                    "show-estimations": _ctx.planningStart,
                    "initial-stories": _ctx.userStories,
                    "show-edit-buttons": true,
                    "select-story": true,
                    host: true,
                    "story-mode": _ctx.userStoryMode,
                    "splitted-user-stories": _ctx.splitted_user_stories,
                    "story-to-split-idx": _ctx.index,
                    "has-api-key": _ctx.hasApiKey,
                    onUserStoriesChanged: _ctx.onUserStoriesChanged,
                    onSelectedStory: _cache[6] || (_cache[6] = ($event: any) => (_ctx.onSelectedStory($event))),
                    onSendGPTRequest: _ctx.splitUserStory
                  }, null, 8, ["card-set", "show-estimations", "initial-stories", "story-mode", "splitted-user-stories", "story-to-split-idx", "has-api-key", "onUserStoriesChanged", "onSendGPTRequest"])),
                  (_ctx.userStoryMode === 'US_JIRA')
                    ? (_openBlock(), _createElementBlock("div", _hoisted_11, [
                        _createVNode(_component_b_button, {
                          variant: "primary",
                          class: "w-100 mb-3",
                          onClick: _cache[7] || (_cache[7] = ($event: any) => {
              _ctx.refreshUserStories();
              $event.target.blur();
            })
                        }, {
                          default: _withCtx(() => [
                            _createTextVNode(_toDisplayString(_ctx.t("page.session.before.refreshStories")), 1)
                          ], undefined, true),
                          _: 1
                        })
                      ]))
                    : _createCommentVNode("", true)
                ], undefined, true),
                _: 1
              }),
              (_ctx.showSpinner)
                ? (_openBlock(), _createBlock(_component_b_spinner, {
                    key: 0,
                    variant: "primary",
                    class: "position-absolute centerSpinner"
                  }))
                : _createCommentVNode("", true),
              (_ctx.showGPTModal)
                ? (_openBlock(), _createBlock(_component_GptModal, {
                    key: 1,
                    "suggestion-description": _ctx.alternateDescription,
                    "gpt-mode": _ctx.descriptionMode,
                    "retry-repaint": _ctx.updateComponent,
                    onAcceptSuggestionDescription: _ctx.acceptSuggestionDescription,
                    onRetry: _ctx.retrySuggestionDescription,
                    onHideModal: _ctx.closeModal
                  }, null, 8, ["suggestion-description", "gpt-mode", "retry-repaint", "onAcceptSuggestionDescription", "onRetry", "onHideModal"]))
                : _createCommentVNode("", true),
              _createVNode(_component_b_col, {
                cols: "12",
                md: "7"
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_user_story_title, {
                    "alternate-title": _ctx.alternateTitle,
                    "display-ai-option": _ctx.gptTitleResponse,
                    host: true,
                    "initial-stories": _ctx.userStories,
                    "card-set": _ctx.voteSet,
                    index: _ctx.index!,
                    "has-api-key": _ctx.hasApiKey,
                    onUserStoriesChanged: _ctx.onUserStoriesChanged,
                    onImproveTitle: _ctx.improveTitle,
                    onAcceptTitle: _ctx.acceptSuggestionTitle,
                    onAdjustTitle: _ctx.adjustOriginalTitle,
                    onRetryTitle: _ctx.retryImproveTitle,
                    onDeleteTitle: _ctx.deleteTitle,
                    onAiEstimation: _ctx.aiEstimation
                  }, null, 8, ["alternate-title", "display-ai-option", "initial-stories", "card-set", "index", "has-api-key", "onUserStoriesChanged", "onImproveTitle", "onAcceptTitle", "onAdjustTitle", "onRetryTitle", "onDeleteTitle", "onAiEstimation"]),
                  _createVNode(_component_user_story_descriptions, {
                    "initial-stories": _ctx.userStories,
                    "edit-description": true,
                    index: _ctx.index!,
                    "gpt-description-response": _ctx.gptDescriptionResponse,
                    "update-component": _ctx.updateComponent,
                    "accepted-stories": _ctx.acceptedStoriesDescription,
                    "is-jira-selected": _ctx.isJiraSelected,
                    "has-api-key": _ctx.hasApiKey,
                    onUserStoriesChanged: _ctx.onUserStoriesChanged,
                    onSendGPTDescriptionRequest: _ctx.improveDescription
                  }, null, 8, ["initial-stories", "index", "gpt-description-response", "update-component", "accepted-stories", "is-jira-selected", "has-api-key", "onUserStoriesChanged", "onSendGPTDescriptionRequest"])
                ], undefined, true),
                _: 1
              })
            ], undefined, true),
            _: 1
          }))
        : _createCommentVNode("", true),
      _createVNode(_component_notify_host_component)
    ], undefined, true),
    _: 1
  }))
}