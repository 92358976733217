import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_b_button = _resolveComponent("b-button")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(_component_b_button, {
      variant: "primary",
      disabled: _ctx.disabled,
      onClick: _cache[0] || (_cache[0] = ($event: any) => {
        _ctx.redirectToJira();
        $event.target.blur();
      })
    }, {
      default: _withCtx(() => [
        _createTextVNode(_toDisplayString(_ctx.t(
          "session.prepare.step.selection.mode.description.withIssueTracker.buttons.signInWithJiraCloud.label"
        )), 1)
      ], undefined, true),
      _: 1
    }, 8, ["disabled"])
  ]))
}