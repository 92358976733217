import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, resolveDirective as _resolveDirective, createVNode as _createVNode, withDirectives as _withDirectives, withCtx as _withCtx, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, createElementVNode as _createElementVNode, vShow as _vShow } from "vue"

const _hoisted_1 = { class: "list" }
const _hoisted_2 = { class: "list-group list-group-horizontal w-100" }
const _hoisted_3 = { class: "position-relative w-100 text-center" }
const _hoisted_4 = {
  key: 2,
  id: "aiOptions",
  class: "text-center mt-1"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_b_form_textarea = _resolveComponent("b-form-textarea")!
  const _component_b_icon_stars = _resolveComponent("b-icon-stars")!
  const _component_b_button = _resolveComponent("b-button")!
  const _component_b_spinner = _resolveComponent("b-spinner")!
  const _component_b_icon_check2 = _resolveComponent("b-icon-check2")!
  const _component_b_icon_sliders = _resolveComponent("b-icon-sliders")!
  const _component_b_icon_arrow_repeat = _resolveComponent("b-icon-arrow-repeat")!
  const _component_b_icon_backspace = _resolveComponent("b-icon-backspace")!
  const _component_b_dropdown_item = _resolveComponent("b-dropdown-item")!
  const _component_BIconStars = _resolveComponent("BIconStars")!
  const _component_b_dropdown = _resolveComponent("b-dropdown")!
  const _component_b_list_group_item = _resolveComponent("b-list-group-item")!
  const _component_privacy_modal = _resolveComponent("privacy-modal")!
  const _directive_debounce = _resolveDirective("debounce")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.userStories, (story, idx) => {
      return _withDirectives((_openBlock(), _createBlock(_component_b_list_group_item, {
        key: story.id,
        class: "border-0 title-box",
        variant: "outline-secondary"
      }, {
        default: _withCtx(() => [
          _createElementVNode("div", _hoisted_2, [
            _createElementVNode("div", _hoisted_3, [
              _withDirectives(_createVNode(_component_b_form_textarea, {
                id: 'titleInputField_' + idx,
                modelValue: _ctx.userStories[idx].title,
                "onUpdate:modelValue": ($event: any) => ((_ctx.userStories[idx].title) = $event),
                class: "overflow-auto titleInputField",
                rows: "1",
                size: "lg",
                disabled: !_ctx.host,
                placeholder: _ctx.t('page.session.before.userStories.placeholder.userStoryTitle')
              }, null, 8, ["id", "modelValue", "onUpdate:modelValue", "disabled", "placeholder"]), [
                [_directive_debounce, _ctx.showGptButtonTitle, "1s"]
              ]),
              (
              !_ctx.displayAiOption &&
              _ctx.host &&
              _ctx.showImproveTitleButton &&
              (_ctx.savedTitle === '' || _ctx.savedTitle === _ctx.userStories[idx].title) &&
              _ctx.userStories[idx].title !== '' &&
              _ctx.hasApiKey
            )
                ? (_openBlock(), _createBlock(_component_b_button, {
                    key: 0,
                    id: "submitAI",
                    onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.showModalTitle = true))
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_b_icon_stars, { class: "aiStars" })
                    ], undefined, true),
                    _: 1
                  }))
                : _createCommentVNode("", true),
              (_ctx.showSpinner && !_ctx.displayAiOption)
                ? (_openBlock(), _createBlock(_component_b_spinner, { key: 1 }))
                : _createCommentVNode("", true),
              (_ctx.displayAiOption)
                ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
                    _createVNode(_component_b_button, {
                      id: "acceptAIOption",
                      class: "m-1",
                      onClick: _ctx.acceptGptTitle
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_b_icon_check2),
                        _createTextVNode(" " + _toDisplayString(_ctx.t("general.aiFeature.optionButtons.keep")), 1)
                      ], undefined, true),
                      _: 1
                    }, 8, ["onClick"]),
                    _createVNode(_component_b_button, {
                      class: "aiOptionButtons m-1",
                      onClick: _ctx.adjustTitle
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_b_icon_sliders),
                        _createTextVNode(" " + _toDisplayString(_ctx.t("general.aiFeature.optionButtons.adjust")), 1)
                      ], undefined, true),
                      _: 1
                    }, 8, ["onClick"]),
                    _createVNode(_component_b_button, {
                      class: "aiOptionButtons m-1",
                      onClick: _ctx.retryTitle
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_b_icon_arrow_repeat),
                        _createTextVNode(" " + _toDisplayString(_ctx.t("general.aiFeature.optionButtons.tryAgain")), 1)
                      ], undefined, true),
                      _: 1
                    }, 8, ["onClick"]),
                    _createVNode(_component_b_button, {
                      class: "aiOptionButtons m-1",
                      onClick: _ctx.deleteTitle
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_b_icon_backspace),
                        _createTextVNode(" " + _toDisplayString(_ctx.t("general.aiFeature.optionButtons.delete")), 1)
                      ], undefined, true),
                      _: 1
                    }, 8, ["onClick"])
                  ]))
                : _createCommentVNode("", true)
            ]),
            _withDirectives(_createVNode(_component_b_dropdown, {
              variant: "none",
              class: "px-3 ml-3 estimationDescription",
              text: (_ctx.userStories[idx].estimation ? _ctx.userStories[idx].estimation : '?') + '    '
            }, {
              default: _withCtx(() => [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.cardSet, (num) => {
                  return (_openBlock(), _createBlock(_component_b_dropdown_item, {
                    key: num,
                    disabled: !_ctx.host,
                    value: num,
                    onClick: ($event: any) => {
              _ctx.userStories[idx].estimation = num;
              _ctx.publishChanges(idx);
              $event.target.blur();
            }
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(num), 1)
                    ], undefined, true),
                    _: 2
                  }, 1032, ["disabled", "value", "onClick"]))
                }), 128)),
                (_ctx.showAIEstimationButton && _ctx.hasApiKey)
                  ? (_openBlock(), _createBlock(_component_b_dropdown_item, {
                      key: 0,
                      onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.showModalEstimation = true))
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_BIconStars)
                      ], undefined, true),
                      _: 1
                    }))
                  : _createCommentVNode("", true)
              ], undefined, true),
              _: 2
            }, 1032, ["text"]), [
              [_vShow, _ctx.host]
            ])
          ])
        ], undefined, true),
        _: 2
      }, 1024)), [
        [_vShow, idx === _ctx.index]
      ])
    }), 128)),
    (_ctx.showModalTitle)
      ? (_openBlock(), _createBlock(_component_privacy_modal, {
          key: 0,
          "current-text": _ctx.userStories[_ctx.index].title,
          onSendGPTRequest: _ctx.sendGptTitle,
          onResetShowModal: _cache[2] || (_cache[2] = ($event: any) => (_ctx.showModalTitle = false))
        }, null, 8, ["current-text", "onSendGPTRequest"]))
      : _createCommentVNode("", true),
    (_ctx.showModalEstimation && _ctx.showAIEstimationButton)
      ? (_openBlock(), _createBlock(_component_privacy_modal, {
          key: 1,
          "current-title": _ctx.userStories[_ctx.index].title,
          "current-text": _ctx.userStories[_ctx.index].description,
          onSendGPTRequest: _ctx.submitAiEstimation,
          onResetShowModal: _cache[3] || (_cache[3] = ($event: any) => (_ctx.showModalEstimation = false))
        }, null, 8, ["current-title", "current-text", "onSendGPTRequest"]))
      : _createCommentVNode("", true)
  ]))
}