import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_b_img = _resolveComponent("b-img")!
  const _component_b_navbar_brand = _resolveComponent("b-navbar-brand")!
  const _component_b_navbar_toggle = _resolveComponent("b-navbar-toggle")!
  const _component_b_button = _resolveComponent("b-button")!
  const _component_b_nav_form = _resolveComponent("b-nav-form")!
  const _component_BIconGithub = _resolveComponent("BIconGithub")!
  const _component_b_nav_item = _resolveComponent("b-nav-item")!
  const _component_theme_toggle = _resolveComponent("theme-toggle")!
  const _component_b_nav_text = _resolveComponent("b-nav-text")!
  const _component_locale_dropdown = _resolveComponent("locale-dropdown")!
  const _component_b_navbar_nav = _resolveComponent("b-navbar-nav")!
  const _component_b_collapse = _resolveComponent("b-collapse")!
  const _component_b_navbar = _resolveComponent("b-navbar")!

  return (_openBlock(), _createBlock(_component_b_navbar, {
    class: "top-navigation",
    type: "light",
    fixed: "top",
    sticky: ""
  }, {
    default: _withCtx(() => [
      _createVNode(_component_b_navbar_brand, {
        class: "top-navigation__title",
        to: "/"
      }, {
        default: _withCtx(() => [
          _createVNode(_component_b_img, {
            src: "/img/icons/logo.svg",
            width: "48",
            height: "48",
            alt: "Diveni Logo"
          }),
          _createTextVNode(" " + _toDisplayString(_ctx.t("page.landing.productTitle")), 1)
        ], undefined, true),
        _: 1
      }),
      _createVNode(_component_b_navbar_toggle, { target: "nav-collapse" }),
      _createVNode(_component_b_collapse, {
        id: "nav-collapse",
        "is-nav": ""
      }, {
        default: _withCtx(() => [
          _createVNode(_component_b_navbar_nav, { class: "ml-auto" }, {
            default: _withCtx(() => [
              _createVNode(_component_b_nav_form, null, {
                default: _withCtx(() => [
                  _createVNode(_component_b_button, {
                    to: { name: 'PrepareSessionPage' },
                    variant: "primary",
                    class: "mr-2"
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(_ctx.t("page.landing.meeting.new.buttons.start.label")), 1)
                    ], undefined, true),
                    _: 1
                  })
                ], undefined, true),
                _: 1
              }),
              _createVNode(_component_b_nav_form, null, {
                default: _withCtx(() => [
                  _createVNode(_component_b_button, {
                    to: { name: 'JoinPage' },
                    variant: "secondary",
                    class: "mr-2"
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(_ctx.t("page.landing.meeting.join.buttons.start.label")), 1)
                    ], undefined, true),
                    _: 1
                  })
                ], undefined, true),
                _: 1
              }),
              _createVNode(_component_b_nav_item, {
                href: "https://github.com/Sybit-Education/Diveni",
                target: "_blank"
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_BIconGithub, { class: "github-logo py-2" })
                ], undefined, true),
                _: 1
              }),
              _createVNode(_component_b_nav_text, null, {
                default: _withCtx(() => [
                  _createVNode(_component_theme_toggle, { class: "my-2" })
                ], undefined, true),
                _: 1
              }),
              _createVNode(_component_b_nav_item, null, {
                default: _withCtx(() => [
                  _createVNode(_component_locale_dropdown)
                ], undefined, true),
                _: 1
              })
            ], undefined, true),
            _: 1
          })
        ], undefined, true),
        _: 1
      })
    ], undefined, true),
    _: 1
  }))
}